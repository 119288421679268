import { defineMessages } from 'react-intl';

import { MergeCheckStateChangeType } from 'src/types/custom-merge-checks';

export const messages = defineMessages({
  cancelBtn: {
    id: 'frontbucket.settings.merge-checks.modals.cancel-button',
    description: 'Text for the button to cancel the disable Check modal',
    defaultMessage: 'Cancel',
  },
});

export const contextMessages = {
  [MergeCheckStateChangeType.DISABLE]: defineMessages({
    description: {
      id: 'frontbucket.settings.merge-checks.modals.disable-check-description',
      description: 'Text for the disable Check modal description',
      defaultMessage:
        'This will disable the check and it will not be run for future pull requests. Are you sure you wish to disable "{name}"? You can enable it again if you wish to do so.',
    },
    confirmBtn: {
      id: 'frontbucket.settings.merge-checks.modals.disable-button',
      description: 'Text for the button to confirm disable Check modal',
      defaultMessage: 'Disable',
    },
  }),
  [MergeCheckStateChangeType.REQUIRED_TO_RECOMMENDED]: defineMessages({
    description: {
      id: 'frontbucket.settings.merge-checks.modals.required-to-recommended-check-description',
      description:
        'Text for the required to recommended Check modal description',
      defaultMessage:
        'This will change the check from required to recommended and will not prevent the pull request from merging. Are you sure you wish to change "{name}" to recommended? You can change it back to required if you wish to do so.',
    },
    confirmBtn: {
      id: 'frontbucket.settings.merge-checks.modals.change-to-recommended-button',
      description:
        'Text for the button to confirm change to recommended Check modal',
      defaultMessage: 'Confirm',
    },
  }),
};
