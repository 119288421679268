import {
  BranchConfigsResponse,
  CustomMergeCheck,
  CustomMergeChecksConfigApi,
  CustomMergeChecksConfigurationResponse,
  CustomMergeChecksConfigureApi,
} from 'src/types/custom-merge-checks';
import { createHttpRequest } from 'src/utils/http-request';

export const customMergeChecksConfigApi = (
  url: string
): CustomMergeChecksConfigApi => {
  return {
    getBranchConfigs: () =>
      createHttpRequest<BranchConfigsResponse>('GET')(`${url}/branch-configs`),
    getCustomMergeChecks: () =>
      createHttpRequest<CustomMergeChecksConfigurationResponse>('GET')(url),
  };
};

export const createCustomMergeChecksConfigureApi = (
  url: string
): CustomMergeChecksConfigureApi => {
  return {
    configureCustomMergeCheck: (extensionId, branch, state) =>
      createHttpRequest<CustomMergeCheck>('PUT')(`${url}/configure`, {
        extensionId,
        branch,
        state,
      }),
  };
};
