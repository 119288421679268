var localAnalyticsEnabled;
var localProduct;
var webStorageControlsEnabled;
export var getBscGlobalState = function getBscGlobalState() {
  if (localAnalyticsEnabled == null) {
    localAnalyticsEnabled = true;
  }
  return {
    analyticsEnabled: localAnalyticsEnabled,
    product: localProduct,
    webStorageEnabled: webStorageControlsEnabled
  };
};
export var initializeGlobalState = function initializeGlobalState() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$analyticsEnabled = _ref.analyticsEnabled,
    analyticsEnabled = _ref$analyticsEnabled === void 0 ? true : _ref$analyticsEnabled,
    _ref$product = _ref.product,
    product = _ref$product === void 0 ? 'NONE' : _ref$product,
    _ref$webStorageEnable = _ref.webStorageEnabled,
    webStorageEnabled = _ref$webStorageEnable === void 0 ? false : _ref$webStorageEnable;
  localAnalyticsEnabled = analyticsEnabled;
  localProduct = product;
  webStorageControlsEnabled = webStorageEnabled;
};
export var clearGlobalState = function clearGlobalState() {
  localAnalyticsEnabled = undefined;
  localProduct = undefined;
};