import { useEffect } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import { ForgeMergeCheckModule } from 'src/types/custom-merge-checks';

export const INSTALLED_CUSTOM_MERGE_CHECKS_QUERY = gql`
  query InstalledCustomMergeChecks($workspaceAri: ID!) {
    extensionContext(contextId: $workspaceAri) {
      extensionsByType(type: "bitbucket:mergeCheck") {
        appId
        id
        name
        properties
      }
    }
  }
`;

export type InstalledCustomMergeChecksData = {
  extensionContext: {
    extensionsByType: ForgeMergeCheckModule[];
  };
};

const INSTALLED_CUSTOM_MERGE_CHECKS_POLLING_INTERVAL = 60000;

export const useInstalledCustomMergeChecks = (
  workspaceAri: string
): ReturnType<
  typeof useQuery<InstalledCustomMergeChecksData, { workspaceAri: string }>
> => {
  const query = useQuery(INSTALLED_CUSTOM_MERGE_CHECKS_QUERY, {
    variables: { workspaceAri },
    // Note that due to Apollo caching, subsequent fetches won't set the
    // `loading` flag in the `useQuery` result. This also won't poll
    // unless the `useQuery` hook is actually being rendered.
    pollInterval: INSTALLED_CUSTOM_MERGE_CHECKS_POLLING_INTERVAL,
    errorPolicy: 'all',
  });

  useEffect(() => {
    const onVisibilityChange = () => {
      if (!document.hidden) {
        query.startPolling(INSTALLED_CUSTOM_MERGE_CHECKS_POLLING_INTERVAL);
      } else {
        query.stopPolling();
      }
    };
    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [query]);

  return query;
};
