import { RouteResourceUpdater } from 'react-resource-router';

import {
  BranchConfig,
  CustomMergeCheck,
  CustomMergeCheckConfigRouteResource,
  CustomMergeCheckExtensionId,
  CustomMergeCheckKey,
  CustomMergeCheckResourceId,
  CustomMergeChecksResourceType,
} from 'src/types/custom-merge-checks';

/**
 * Generate a CustomMergeCheckKey that uniquely identifies a custom merge check
 * for a given resource type, branch config and extension ID. These are used to
 * maintain a local map of the configuration state ('off', 'required' or
 * 'recommended') of custom merge checks.
 * @param resourceType the CustomMergeChecksResourceType of the check
 * @param branchConfig the branch config of the check
 * @param extensionId the Forge extension ID of the check
 * @returns a CustomMergeCheckKey identifying a check
 */
export const customMergeCheckKey = (
  resourceType: CustomMergeChecksResourceType,
  branchConfig: BranchConfig,
  extensionId: CustomMergeCheckExtensionId
): CustomMergeCheckKey =>
  `${resourceType}${branchConfig.type}${branchConfig.identifier}${extensionId}`;

export const REPOSITORY_ARI_PREFIX = 'ari:cloud:bitbucket::repository';
export const PROJECT_ARI_PREFIX = 'ari:cloud:bitbucket::project';
export const WORKSPACE_ARI_PREFIX = 'ari:cloud:bitbucket::workspace';

export const determineResourceType = (
  resource: CustomMergeCheckResourceId
): CustomMergeChecksResourceType => {
  if (resource.startsWith(REPOSITORY_ARI_PREFIX)) {
    return CustomMergeChecksResourceType.Repository;
  }

  if (resource.startsWith(PROJECT_ARI_PREFIX)) {
    return CustomMergeChecksResourceType.Project;
  }

  if (resource.startsWith(WORKSPACE_ARI_PREFIX)) {
    return CustomMergeChecksResourceType.Workspace;
  }

  throw new Error(`Invalid resource ID ${resource}`);
};

export const updateCustomMergeCheckConfigRouteResource =
  ({
    extensionId,
    branchConfig,
    state,
    resource,
  }: CustomMergeCheck): RouteResourceUpdater<CustomMergeCheckConfigRouteResource> =>
  data => {
    const updatedStateMap = new Map(data.stateMap);
    const key = customMergeCheckKey(
      determineResourceType(resource),
      branchConfig,
      extensionId
    );
    updatedStateMap.set(key, state);
    return {
      ...data,
      stateMap: updatedStateMap,
    };
  };
