import { LINE_PERMALINK_MATCHER } from '@atlassian/bitkit-diff/exports/diff-permalink';
import { LineAnnotation } from '@atlassian/bitkit-diff/exports/types';

import {
  ApiComment,
  CodeReviewConversation,
  isApiComment,
  isFileComment,
  PlaceholderApiComment,
} from 'src/components/conversation-provider/types';
import { Link } from 'src/components/types';
import { LoadingStatus } from 'src/constants/loading-status';
import { UPDATE_GLOBAL_DIFF_VIEW_MODE_SUCCESS } from 'src/redux/diff-settings';
import { NETWORK_ONLINE, LoadGlobal } from 'src/redux/global/actions';
import { reconcileChunks } from 'src/redux/pull-request/utils/reconcile-chunks';
import { FetchSourceRepositoryDetails } from 'src/sections/repository/actions';
import {
  SourceRepositoryDetailsState,
  initialStateSourceRepository,
} from 'src/sections/repository/reducers/details';
import { DetailedBranch } from 'src/sections/repository/sections/branches/types';
import { Conflict, MergeCheck } from 'src/types';
import { CodeInsightsResult } from 'src/types/code-insights';
import { DiffStat } from 'src/types/diffstat';
import { Diff } from 'src/types/pull-request';
import createReducer from 'src/utils/create-reducer';
import { extractFilepath } from 'src/utils/extract-file-path';

import * as Actions from './actions';
import { DiffStateMap } from './actions/diffs-expansion';
import { ActivityState, activityInitialState } from './activity-reducer';
import { declineInitialState } from './decline-reducer';
import { imageUploadInitialState } from './image-upload-reducer';
import { IterativeReviewState } from './iterative-review-reducer';
import {
  CREATE_PENDING_MERGE,
  mergeInitialState,
  MergeState,
} from './merge-reducer';
import {
  PullRequestPollingState,
  initialState as pollingInitialState,
} from './polling-reducer';
import {
  PullRequestTasksState,
  initialState as tasksInitialState,
} from './tasks-reducer';
import { mergeContextLinesWithChunks } from './utils/merge-context-lines-with-chunks';
import { viewEntireFileInitialState } from './view-entire-file-reducer';

export type PullRequestState = {
  activeDiff: string;
  activity: ActivityState;
  activePermalink: string;
  annotations?: LineAnnotation[];
  diffsExpansionState: DiffStateMap;
  diffCommentingMap: DiffStateMap;
  sideBySideDiffState: DiffStateMap;
  isMergeable: boolean;
  contextLines: { [key: string]: string | undefined };
  conflicts: Conflict[];
  currentPullRequest: string | null | undefined;
  decline: typeof declineInitialState;
  defaultMergeStrategy: string | null | undefined;
  diff: Diff[];
  diffStat: DiffStat[] | undefined;
  error: boolean;
  errorMessage?: string;
  imageUpload: typeof imageUploadInitialState;
  isRequestingChangesDisabled: boolean;
  isRequestingChangesError: boolean;
  isRequestingChangesPending: boolean;
  isDiffsLoading: boolean;
  isDiffStatLoading: boolean;
  isErrorDialogOpen: boolean;
  isLoadingOutdatedCommentContext: boolean;
  isLoadingBaseRevCommentContext: boolean;
  isLoadingAllChangesCommentContext: boolean;
  isLoadingPendingCommentContext: boolean;
  isMergeChecksLoading: boolean;
  isOutdatedDialogOpen: boolean;
  isReviewDraftDialogOpen: boolean;
  isDiffCommentsDialogOpen: boolean;
  isSingleFileMode: boolean;
  isSingleFileModeEligible: boolean;
  isSingleFileModeSettingsHeaderVisible: boolean;
  isStickyHeaderActive: boolean;
  isNonRenderedDiffCommentsDialogOpen: boolean;
  isWatching: boolean;
  lastPullRequestRetrieved: number | null;
  merge: MergeState;
  mergeInProgress: Link | null;
  mergeChecks: MergeCheck[];
  mergeChecksError: string;
  mergeChecksSource: string;
  outdatedCommentsDialog: string | undefined;
  baseRevCommentsDialog: string | undefined;
  diffCommentsDialog?: string;
  nonRenderedDiffCommentsDialogFilepath?: string;
  polling: PullRequestPollingState;
  rawComments: (ApiComment | PlaceholderApiComment)[];
  commentsLoadingStatus: LoadingStatus;
  isCommentsFilterActive: boolean;
  filterCommentsByUser: BB.User['uuid'] | null;
  tasks: PullRequestTasksState;
  watchActionLoading: boolean;
  diffErrorCode?: number | string | null;
  diffStatErrorCode?: number | string | null;
  diffStatErrorKey: string | null;
  isEditorOpen: boolean;
  isRevertDialogOpen: boolean;
  revertError: string | null;
  diffSort: 'fileTree' | 'latestComments';
  // The anchor ID for the currently open dialog, or null to close the dialog
  hiddenFileDialogAnchor: string | null;
  codeInsightsReports?: CodeInsightsResult[];
  isCodeInsightsReportsLoading?: boolean;
  hasCodeInsightsReportsError?: boolean;
  codeInsightsAnnotations: { [key: string]: LineAnnotation[] };
  hiddenAnnotations: string[];
  hiddenDiffComments: string[];
  isCodeInsightsAnnotationsLoading: boolean;
  hasDestinationBranch: boolean;
  sourceRepository: SourceRepositoryDetailsState;
  sourceBranchDetails: DetailedBranch | undefined;
  canCurrentUserMerge: boolean;
  viewEntireFile: typeof viewEntireFileInitialState;
  highlightedCommentId: number | null;
  highlightCommentThread: boolean | null;
  isSettingsChangeboardingDialogOpen: boolean;
  sidebarCardCollapsed: { [key: string]: boolean };
  isPullRequestTitleUpdatePending: boolean;
  isPullRequestDescriptionUpdatePending: boolean;
  isPullRequestRemoveReviewerPending: boolean;
  pullRequestAddReviewerPendingAaids: string[];
  isMissingCommits: boolean;
  isUpdatingCommits: boolean;
  hasMissingCommitsError: boolean;
  refetchBuildsDummy: boolean;
  iterativeReview: IterativeReviewState;
  refetchIRDiffDummy: boolean;
};

export const initialState: PullRequestState = {
  activeDiff: '',
  activity: activityInitialState,
  activePermalink: '',
  annotations: undefined,
  contextLines: {},
  diffsExpansionState: {},
  sideBySideDiffState: {},
  isMergeable: false,
  conflicts: [],
  currentPullRequest: null,
  decline: declineInitialState,
  defaultMergeStrategy: null,
  diff: [],
  diffCommentingMap: {},
  diffStat: undefined,
  error: false,
  errorMessage: '',
  imageUpload: imageUploadInitialState,
  isRequestingChangesDisabled: false,
  isRequestingChangesError: false,
  isRequestingChangesPending: false,
  isDiffsLoading: false,
  isDiffStatLoading: false,
  isErrorDialogOpen: false,
  isLoadingOutdatedCommentContext: false,
  isLoadingBaseRevCommentContext: false,
  isLoadingAllChangesCommentContext: false,
  isLoadingPendingCommentContext: false,
  isMergeChecksLoading: false,
  isOutdatedDialogOpen: false,
  isReviewDraftDialogOpen: false,
  isDiffCommentsDialogOpen: false,
  isSingleFileMode: false,
  isSingleFileModeEligible: false,
  isSingleFileModeSettingsHeaderVisible: true,
  isStickyHeaderActive: false,
  isNonRenderedDiffCommentsDialogOpen: false,
  isWatching: false,
  lastPullRequestRetrieved: null,
  merge: mergeInitialState,
  mergeInProgress: null,
  mergeChecks: [],
  mergeChecksError: '',
  mergeChecksSource: '',
  outdatedCommentsDialog: undefined,
  baseRevCommentsDialog: undefined,
  polling: pollingInitialState,
  rawComments: [],
  commentsLoadingStatus: LoadingStatus.Before,
  tasks: tasksInitialState,
  watchActionLoading: true,
  isCommentsFilterActive: false,
  filterCommentsByUser: null,
  diffErrorCode: null,
  diffStatErrorCode: null,
  diffStatErrorKey: null,
  isEditorOpen: false,
  isRevertDialogOpen: false,
  revertError: null,
  diffSort: 'fileTree',
  hiddenFileDialogAnchor: null,
  codeInsightsAnnotations: {},
  isCodeInsightsAnnotationsLoading: false,
  hasDestinationBranch: true,
  sourceRepository: initialStateSourceRepository,
  sourceBranchDetails: undefined,
  canCurrentUserMerge: false,
  viewEntireFile: viewEntireFileInitialState,
  hiddenAnnotations: [],
  hiddenDiffComments: [],
  highlightedCommentId: null,
  highlightCommentThread: null,
  isSettingsChangeboardingDialogOpen: true,
  sidebarCardCollapsed: {},
  isPullRequestTitleUpdatePending: false,
  isPullRequestDescriptionUpdatePending: false,
  isPullRequestRemoveReviewerPending: false,
  pullRequestAddReviewerPendingAaids: [],
  isMissingCommits: false,
  isUpdatingCommits: false,
  hasMissingCommitsError: false,
  refetchBuildsDummy: false,
  iterativeReview: {
    isIterativeReviewComplete: false,
  },
  refetchIRDiffDummy: false,
};

function updateFilterCommentsByUser(
  state: PullRequestState,
  action: {
    type: string;
    payload: string | null;
  }
) {
  const updatedId: BB.User['uuid'] | null = action.payload;
  // deselect filter if it's the same
  if (
    state.isCommentsFilterActive &&
    updatedId === state.filterCommentsByUser
  ) {
    return {
      ...state,
      isCommentsFilterActive: false,
      filterCommentsByUser: null,
    };
  }
  return {
    ...state,
    isCommentsFilterActive: true,
    filterCommentsByUser: updatedId,
  };
}

function changeDiffSort(
  state: PullRequestState,
  action: {
    type: string;
    payload: any;
  }
) {
  return {
    ...state,
    diffSort: action.payload,
  };
}

function mergeContextLines(
  state: PullRequestState,
  action: {
    type: string;
    payload?: CodeReviewConversation[];
  }
) {
  const { payload: convos } = action;

  const newContextLines = { ...state.contextLines };

  if (convos) {
    // We map each context lines value to its convo id, overriding any old values
    convos.forEach(convo => {
      newContextLines[convo.conversationId] = convo.meta.context_lines;
    });
  }

  return {
    ...state,
    contextLines: newContextLines,
    isLoadingOutdatedCommentContext:
      action.type === Actions.FETCH_OUTDATED_COMMENT_CONTEXT.SUCCESS
        ? false
        : state.isLoadingOutdatedCommentContext,
    isLoadingPendingCommentContext:
      action.type === Actions.FETCH_PENDING_COMMENT_CONTEXT.SUCCESS
        ? false
        : state.isLoadingPendingCommentContext,
    isLoadingBaseRevCommentContext:
      action.type === Actions.FETCH_BASE_REV_COMMENT_CONTEXT.SUCCESS
        ? false
        : state.isLoadingBaseRevCommentContext,
    isLoadingAllChangesCommentContext:
      action.type === Actions.FETCH_ALL_CHANGES_COMMENT_CONTEXT.SUCCESS
        ? false
        : state.isLoadingAllChangesCommentContext,
  };
}

export const pullRequestReducer = createReducer(initialState, {
  [Actions.FETCH_CONFLICTS.SUCCESS](state, action) {
    return {
      ...state,
      conflicts: action.payload,
    };
  },
  [Actions.FETCH_MERGE_CHECKS.REQUEST](state) {
    return {
      ...state,
      isMergeChecksLoading: true,
      isMergeable: false,
    };
  },
  [Actions.FETCH_MERGE_CHECKS.SUCCESS](state, action) {
    const {
      mergeChecks,
      isMergeable,
      canCurrentUserMerge,
      isMissingCommits,
      mergeChecksSource,
    } = action.payload;

    return {
      ...state,
      isMergeChecksLoading: false,
      mergeChecks,
      isMergeable,
      canCurrentUserMerge,
      isMissingCommits,
      mergeChecksError: initialState.mergeChecksError,
      mergeChecksSource,
    };
  },
  [Actions.FETCH_MERGE_CHECKS.ERROR](state, action) {
    return {
      ...state,
      mergeChecksError: action.payload,
      isMergeChecksLoading: false,
    };
  },
  [Actions.FETCH_MERGE_CHECKS_RETRY](state) {
    return {
      ...state,
      isMergeChecksLoading: true,
    };
  },
  [Actions.LOAD_PULL_REQUEST.SUCCESS](state, action) {
    const { result } = action.payload;
    return {
      ...state,
      ...result,
      lastPullRequestRetrieved: Date.now(),
    };
  },
  [Actions.UNLOAD_PULL_REQUEST](state) {
    const {
      contextLines,
      currentPullRequest,
      diff,
      diffStat,
      rawComments,
      codeInsightsReports,
      annotations,
      isCommentsFilterActive,
      filterCommentsByUser,
      diffSort,
      highlightedCommentId,
      highlightCommentThread,
      isOutdatedDialogOpen,
      outdatedCommentsDialog,
      diffErrorCode,
      diffStatErrorCode,
      diffStatErrorKey,
    } = initialState;

    return {
      ...state,
      contextLines,
      diff,
      diffStat,
      currentPullRequest,
      rawComments,
      codeInsightsReports,
      annotations,
      isCommentsFilterActive,
      filterCommentsByUser,
      diffSort,
      highlightedCommentId,
      highlightCommentThread,
      isOutdatedDialogOpen,
      outdatedCommentsDialog,
      diffErrorCode,
      diffStatErrorCode,
      diffStatErrorKey,
    };
  },
  [Actions.CLEAR_IN_PROGRESS_MERGE_TASK](state, _action) {
    return {
      ...state,
      mergeInProgress: null,
    };
  },
  [Actions.UPDATE_PARTICIPANTS.SUCCESS](state, action) {
    return {
      ...state,
      currentPullRequest: action.payload.result.currentPullRequest,
    };
  },
  [Actions.UPDATE_TITLE.REQUEST](state) {
    return {
      ...state,
      isPullRequestTitleUpdatePending: true,
    };
  },
  [Actions.UPDATE_TITLE.ERROR](state) {
    return {
      ...state,
      isPullRequestTitleUpdatePending: false,
    };
  },
  [Actions.UPDATE_TITLE.SUCCESS](state, action) {
    return {
      ...state,
      isPullRequestTitleUpdatePending: false,
      currentPullRequest: action.payload.result.currentPullRequest,
    };
  },
  [Actions.UPDATE_DESCRIPTION.REQUEST](state) {
    return {
      ...state,
      isPullRequestDescriptionUpdatePending: true,
    };
  },
  [Actions.UPDATE_DESCRIPTION.ERROR](state) {
    return {
      ...state,
      isPullRequestDescriptionUpdatePending: false,
    };
  },
  [Actions.UPDATE_DESCRIPTION.SUCCESS](state, action) {
    return {
      ...state,
      isPullRequestDescriptionUpdatePending: false,
      currentPullRequest: action.payload.result.currentPullRequest,
    };
  },
  [Actions.REMOVE_REVIEWER.REQUEST](state) {
    return {
      ...state,
      isPullRequestRemoveReviewerPending: true,
    };
  },
  [Actions.REMOVE_REVIEWER.ERROR](state) {
    return {
      ...state,
      isPullRequestRemoveReviewerPending: false,
    };
  },
  [Actions.REMOVE_REVIEWER.SUCCESS](state, action) {
    return {
      ...state,
      isPullRequestRemoveReviewerPending: false,
      currentPullRequest: action.payload.result.currentPullRequest,
    };
  },
  [Actions.ADD_REVIEWER.REQUEST](state, action) {
    return {
      ...state,
      pullRequestAddReviewerPendingAaids: [
        ...state.pullRequestAddReviewerPendingAaids,
        ...(action.payload.userAaid ? [action.payload.userAaid] : []),
      ],
    };
  },
  [Actions.ADD_REVIEWER.ERROR](state, action) {
    return {
      ...state,
      pullRequestAddReviewerPendingAaids:
        state.pullRequestAddReviewerPendingAaids.filter(
          id => id !== action.payload.userAaid
        ),
    };
  },
  [Actions.ADD_REVIEWER.SUCCESS](state, action) {
    return {
      ...state,
      pullRequestAddReviewerPendingAaids:
        state.pullRequestAddReviewerPendingAaids.filter(
          id => id !== action.payload.result.userAaid
        ),
      currentPullRequest: action.payload.result.currentPullRequest,
    };
  },
  [Actions.FETCH_DIFF_FILE](state, action) {
    const { payload: diff } = action;
    return {
      ...state,
      diff: state.diff.concat(diff),
      isDiffsLoading: false,
      diffErrorCode: null,
    };
  },
  [Actions.FETCH_DIFF.REQUEST](state) {
    return {
      ...state,
      isDiffsLoading: true,
    };
  },
  [Actions.FETCH_DIFF.SUCCESS](state, action) {
    const { payload: diff } = action;
    return {
      ...state,
      diff,
      isDiffsLoading: false,
      diffErrorCode: null,
    };
  },
  [Actions.FETCH_DIFF.ERROR](state, action) {
    const { payload: diffErrorCode } = action;

    return {
      ...state,
      isDiffsLoading: false,
      diffErrorCode,
    };
  },
  [Actions.FETCH_PULL_REQUEST_ANNOTATIONS.SUCCESS](state, action) {
    const { payload: annotations } = action;
    return {
      ...state,
      annotations,
    };
  },
  [Actions.FETCH_CODE_INSIGHTS_REPORTS.REQUEST](state) {
    return {
      ...state,
      isCodeInsightsReportsLoading: true,
    };
  },
  [Actions.FETCH_CODE_INSIGHTS_REPORTS.ERROR](state) {
    return {
      ...state,
      isCodeInsightsReportsLoading: false,
      hasCodeInsightsReportsError: true,
    };
  },
  [Actions.FETCH_CODE_INSIGHTS_REPORTS.SUCCESS](state, action) {
    const { payload: codeInsightsReports } = action;
    return {
      ...state,
      isCodeInsightsReportsLoading: false,
      codeInsightsReports,
    };
  },
  [Actions.FETCH_CODE_INSIGHTS_ANNOTATIONS.REQUEST](state) {
    return {
      ...state,
      isCodeInsightsAnnotationsLoading: true,
    };
  },
  [Actions.FETCH_CODE_INSIGHTS_ANNOTATIONS.ERROR](state) {
    return {
      ...state,
      isCodeInsightsAnnotationsLoading: false,
    };
  },
  [Actions.FETCH_CODE_INSIGHTS_ANNOTATIONS.SUCCESS](state, action) {
    return {
      ...state,
      isCodeInsightsAnnotationsLoading: false,
      codeInsightsAnnotations: {
        ...(state.codeInsightsAnnotations || {}),
        ...(action.meta && action.meta.reportId
          ? { [action.meta.reportId]: action.payload }
          : {}),
      },
    };
  },
  [Actions.TOGGLE_ANNOTATIONS](state, action) {
    return {
      ...state,
      hiddenAnnotations: state.hiddenAnnotations.includes(action.payload)
        ? state.hiddenAnnotations.filter(
            annotation => annotation !== action.payload
          )
        : state.hiddenAnnotations.concat(action.payload),
    };
  },
  [Actions.TOGGLE_DIFF_COMMENTS](state, action) {
    return {
      ...state,
      hiddenDiffComments: state.hiddenDiffComments.includes(action.payload)
        ? state.hiddenDiffComments.filter(
            filepath => filepath !== action.payload
          )
        : state.hiddenDiffComments.concat(action.payload),
    };
  },
  [Actions.FETCH_COMMENT_CONTEXT.SUCCESS](state, action) {
    const { newChunk, path } = action.payload;
    const { diff } = state;

    const targetDiff = diff.find(
      currentDiff =>
        currentDiff.to === path ||
        (currentDiff.to === null && currentDiff.from === path)
    );

    if (!targetDiff) {
      return state;
    }

    const updatedDiff = {
      ...targetDiff,
      chunks: reconcileChunks([newChunk, ...targetDiff.chunks]),
    };

    return {
      ...state,
      diff: [
        ...diff.slice(0, diff.indexOf(targetDiff)),
        updatedDiff,
        ...diff.slice(diff.indexOf(targetDiff) + 1),
      ],
    };
  },
  [Actions.EXPAND_CONTEXT.SUCCESS](state, action) {
    const { fileIndex, chunkId, beforeOrAfter, contextLines, hasMoreLines } =
      action.payload;
    const { diff } = state;
    const newDiff = [...diff];
    const newFile = { ...diff[fileIndex] };

    newFile.chunks = mergeContextLinesWithChunks(
      diff[fileIndex].chunks,
      chunkId,
      contextLines,
      beforeOrAfter,
      hasMoreLines
    );

    newDiff[fileIndex] = newFile;

    return {
      ...state,
      diff: newDiff,
    };
  },
  [Actions.EXPAND_CONTEXT.ERROR](state, action) {
    const { fileIndex } = action.payload;
    const { diff } = state;
    const newDiff = [...diff];

    for (let i = 0; i < newDiff[fileIndex].chunks.length; i++) {
      const chunk = newDiff[fileIndex].chunks[i];
      chunk.extra.isLoadingAbove = false;
      chunk.extra.isLoadingBelow = false;
    }

    return {
      ...state,
      diff: newDiff,
    };
  },
  [Actions.REQUEST_CHANGES.REQUEST](state) {
    return {
      ...state,
      isRequestingChangesDisabled: true,
      isRequestingChangesPending: true,
    };
  },
  [Actions.REQUEST_CHANGES.SUCCESS](state) {
    return {
      ...state,
      errorMessage: '',
      isRequestingChangesDisabled: false,
      isRequestingChangesError: false,
      isRequestingChangesPending: false,
    };
  },
  [Actions.REQUEST_CHANGES.ERROR](state, action) {
    return {
      ...state,
      errorMessage: action.payload,
      isRequestingChangesDisabled: false,
      isRequestingChangesError: true,
      isRequestingChangesPending: false,
      isErrorDialogOpen: true,
    };
  },
  [Actions.UNDO_REQUEST_CHANGES.REQUEST](state) {
    return {
      ...state,
      isRequestingChangesDisabled: true,
      isRequestingChangesPending: true,
    };
  },
  [Actions.UNDO_REQUEST_CHANGES.SUCCESS](state) {
    return {
      ...state,
      errorMessage: '',
      isRequestingChangesDisabled: false,
      isRequestingChangesError: false,
      isRequestingChangesPending: false,
    };
  },
  [Actions.HIDE_ERROR_DIALOG](state) {
    return {
      ...state,
      isErrorDialogOpen: false,
    };
  },
  [Actions.APPROVAL_ERROR](state, action) {
    return {
      ...state,
      isErrorDialogOpen: true,
      errorMessage: action.payload.message,
    };
  },
  [NETWORK_ONLINE](state) {
    return {
      ...state,
      isErrorDialogOpen: false,
    };
  },
  [Actions.SET_APPROVAL_LOADER](state) {
    return {
      ...state,
      isApprovalPending: true,
    };
  },
  [Actions.FETCH_COMMENTS.REQUEST](state) {
    return {
      ...state,
      rawComments: [],
      commentsLoadingStatus: LoadingStatus.Fetching,
    };
  },
  [Actions.CLEAR_COMMENTS](state) {
    return {
      ...state,
      rawComments: [],
    };
  },
  [Actions.FETCH_COMMENTS.SUCCESS](state, action) {
    const { rawComments } = action.payload;
    return {
      ...state,
      rawComments,
      commentsLoadingStatus: LoadingStatus.Success,
    };
  },
  [Actions.ADD_COMMENT.REQUEST](state, action) {
    const comment: PlaceholderApiComment = action.payload;
    const nextDiffCommentingMap = isFileComment(comment)
      ? {
          ...state.diffCommentingMap,
          [comment.inline.path]: false,
        }
      : state.diffCommentingMap;
    return {
      ...state,
      rawComments: [
        ...state.rawComments,
        {
          ...comment,
          state: 'SAVING',
        },
      ],
      diffCommentingMap: nextDiffCommentingMap,
    };
  },
  [Actions.ADD_COMMENT.SUCCESS](state, action) {
    const {
      comment,
      placeholderId,
    }: { comment: ApiComment; placeholderId: string } = action.payload;
    // placeholder comment will be replaced with comment from response
    if (placeholderId) {
      const foundComment = state.rawComments.find(
        c => 'placeholderId' in c && c.placeholderId === placeholderId
      );
      if (foundComment) {
        const index = state.rawComments.indexOf(foundComment);
        const newComment = {
          ...foundComment,
          ...comment,
          placeholderId: undefined,
          state: undefined,
        };
        const commentsBefore = state.rawComments.slice(0, index);
        const commentsAfter = state.rawComments.slice(index + 1);
        return {
          ...state,
          rawComments: [...commentsBefore, newComment, ...commentsAfter],
        };
      }
    }
    const nextDiffCommentingMap = isFileComment(comment)
      ? {
          ...state.diffCommentingMap,
          [comment.inline.path]: false,
        }
      : state.diffCommentingMap;
    return {
      ...state,
      rawComments: [
        ...state.rawComments,
        {
          ...comment,
          placeholderId: undefined,
          state: undefined,
        },
      ],
      diffCommentingMap: nextDiffCommentingMap,
    };
  },
  [Actions.UPDATE_COMMENT.REQUEST](state, action) {
    const { id, content } = action.payload;
    const foundComment = state.rawComments.find(
      comment => isApiComment(comment) && comment.id === id
    );
    if (!foundComment) {
      return state;
    }

    const index = state.rawComments.indexOf(foundComment);
    const newComment = {
      ...foundComment,
      content: {
        doc: content,
      },
      state: 'SAVING',
    };

    const commentsBefore = state.rawComments.slice(0, index);
    const commentsAfter = state.rawComments.slice(index + 1);

    return {
      ...state,
      rawComments: [...commentsBefore, newComment, ...commentsAfter],
    };
  },
  [Actions.UPDATE_COMMENT.SUCCESS](state, action) {
    const { comment } = action.payload;
    const foundComment = state.rawComments.find(
      c => isApiComment(c) && c.id === comment.id
    );
    if (!foundComment) {
      return state;
    }

    const index = state.rawComments.indexOf(foundComment);
    const newComment = {
      ...foundComment,
      ...comment,
      state: undefined,
    };

    const commentsBefore = state.rawComments.slice(0, index);
    const commentsAfter = state.rawComments.slice(index + 1);

    return {
      ...state,
      rawComments: [...commentsBefore, newComment, ...commentsAfter],
    };
  },
  [Actions.DELETE_COMMENT.REQUEST](state, action) {
    const { id } = action.payload;
    const foundComment = state.rawComments.find(
      comment => isApiComment(comment) && comment.id === id
    );

    if (!foundComment) {
      return state;
    }

    const index = state.rawComments.indexOf(foundComment);
    const newComment = {
      ...foundComment,
      state: 'SAVING',
    };

    const commentsBefore = state.rawComments.slice(0, index);
    const commentsAfter = state.rawComments.slice(index + 1);

    return {
      ...state,
      rawComments: [...commentsBefore, newComment, ...commentsAfter],
    };
  },
  [Actions.DELETE_COMMENT.SUCCESS](state, action) {
    const foundComment = state.rawComments.find(
      comment => isApiComment(comment) && comment.id === action.payload.id
    );

    if (!foundComment) {
      return state;
    }

    const index = state.rawComments.indexOf(foundComment);
    const newComment = {
      ...foundComment,
      state: undefined,
      deleted: true,
    };

    const commentsBefore = state.rawComments.slice(0, index);
    const commentsAfter = state.rawComments.slice(index + 1);

    return {
      ...state,
      rawComments: [...commentsBefore, newComment, ...commentsAfter],
    };
  },
  [Actions.RESOLVE_COMMENT.REQUEST](state, action) {
    const { id } = action.payload;
    const foundComment = state.rawComments.find(
      comment => isApiComment(comment) && comment.id === id
    );

    if (!foundComment) {
      return state;
    }

    const index = state.rawComments.indexOf(foundComment);
    const newComment = {
      ...foundComment,
      state: 'SAVING',
    };

    const commentsBefore = state.rawComments.slice(0, index);
    const commentsAfter = state.rawComments.slice(index + 1);

    return {
      ...state,
      rawComments: [...commentsBefore, newComment, ...commentsAfter],
    };
  },
  [Actions.RESOLVE_COMMENT.SUCCESS](state, action) {
    const { id, resolution } = action.payload;
    const foundComment = state.rawComments.find(
      comment => isApiComment(comment) && comment.id === id
    );

    if (!foundComment) {
      return state;
    }

    const index = state.rawComments.indexOf(foundComment);
    const newComment = {
      ...foundComment,
      state: undefined,
      resolution,
    };

    const commentsBefore = state.rawComments.slice(0, index);
    const commentsAfter = state.rawComments.slice(index + 1);

    return {
      ...state,
      rawComments: [...commentsBefore, newComment, ...commentsAfter],
    };
  },
  [Actions.REOPEN_COMMENT.REQUEST](state, action) {
    const { id } = action.payload;
    const foundComment = state.rawComments.find(
      comment => isApiComment(comment) && comment.id === id
    );

    if (!foundComment) {
      return state;
    }

    const index = state.rawComments.indexOf(foundComment);
    const newComment = {
      ...foundComment,
      state: 'SAVING',
    };

    const commentsBefore = state.rawComments.slice(0, index);
    const commentsAfter = state.rawComments.slice(index + 1);

    return {
      ...state,
      rawComments: [...commentsBefore, newComment, ...commentsAfter],
    };
  },
  [Actions.REOPEN_COMMENT.SUCCESS](state, action) {
    const { id } = action.payload;
    const foundComment = state.rawComments.find(
      comment => isApiComment(comment) && comment.id === id
    );

    if (!foundComment) {
      return state;
    }

    const index = state.rawComments.indexOf(foundComment);
    const newComment = {
      ...foundComment,
      state: undefined,
      resolution: undefined,
    };

    const commentsBefore = state.rawComments.slice(0, index);
    const commentsAfter = state.rawComments.slice(index + 1);

    return {
      ...state,
      rawComments: [...commentsBefore, newComment, ...commentsAfter],
    };
  },
  [Actions.FETCH_OUTDATED_COMMENT_CONTEXT.REQUEST](state) {
    return { ...state, isLoadingOutdatedCommentContext: true };
  },
  [Actions.FETCH_OUTDATED_COMMENT_CONTEXT.ERROR](state) {
    return { ...state, isLoadingOutdatedCommentContext: false };
  },
  [Actions.FETCH_OUTDATED_COMMENT_CONTEXT.SUCCESS]: mergeContextLines,
  [Actions.FETCH_BASE_REV_COMMENT_CONTEXT.REQUEST](state) {
    return { ...state, isLoadingBaseRevCommentContext: true };
  },
  [Actions.FETCH_BASE_REV_COMMENT_CONTEXT.ERROR](state) {
    return { ...state, isLoadingBaseRevCommentContext: false };
  },
  [Actions.FETCH_BASE_REV_COMMENT_CONTEXT.SUCCESS]: mergeContextLines,
  [Actions.FETCH_ALL_CHANGES_COMMENT_CONTEXT.REQUEST](state) {
    return { ...state, isLoadingAllChangesCommentContext: true };
  },
  [Actions.FETCH_ALL_CHANGES_COMMENT_CONTEXT.ERROR](state) {
    return { ...state, isLoadingAllChangesCommentContext: false };
  },
  [Actions.FETCH_ALL_CHANGES_COMMENT_CONTEXT.SUCCESS]: mergeContextLines,
  [Actions.FETCH_PENDING_COMMENT_CONTEXT.REQUEST](state) {
    return { ...state, isLoadingPendingCommentContext: true };
  },
  [Actions.FETCH_PENDING_COMMENT_CONTEXT.ERROR](state) {
    return { ...state, isLoadingPendingCommentContext: false };
  },
  [Actions.FETCH_PENDING_COMMENT_CONTEXT.SUCCESS]: mergeContextLines,
  [Actions.FETCH_LARGE_FILE_COMMENT_CONTEXT.SUCCESS]: mergeContextLines,
  [Actions.OPEN_OUTDATED_COMMENTS_DIALOG](state, action) {
    return {
      ...state,
      isOutdatedDialogOpen: true,
      outdatedCommentsDialog: action.payload,
    };
  },
  [Actions.OPEN_REVIEW_DRAFT_DIALOG](state) {
    return {
      ...state,
      isReviewDraftDialogOpen: true,
    };
  },
  [Actions.OPEN_DIFF_COMMENTS_DIALOG](state, action) {
    return {
      ...state,
      isDiffCommentsDialogOpen: true,
      diffCommentsDialog: action.payload,
    };
  },
  [Actions.OPEN_NONRENDERED_DIFF_COMMENTS_DIALOG](state, action) {
    return {
      ...state,
      isNonRenderedDiffCommentsDialogOpen: true,
      nonRenderedDiffCommentsDialogFilepath: action.payload,
    };
  },
  [Actions.CLOSE_OUTDATED_COMMENTS_DIALOG](state, _action) {
    return {
      ...state,
      isOutdatedDialogOpen: false,
    };
  },
  [Actions.CLOSE_REVIEW_DRAFT_DIALOG](state) {
    return {
      ...state,
      isReviewDraftDialogOpen: false,
    };
  },
  [Actions.CLOSE_DIFF_COMMENTS_DIALOG](state, _action) {
    return {
      ...state,
      isDiffCommentsDialogOpen: false,
    };
  },
  [Actions.CLOSE_NONRENDERED_DIFF_COMMENTS_DIALOG](state, _action) {
    return {
      ...state,
      isNonRenderedDiffCommentsDialogOpen: false,
    };
  },
  [Actions.LOAD_DIFFSTAT.REQUEST](state) {
    return {
      ...state,
      isDiffStatLoading: true,
    };
  },
  [Actions.LOAD_DIFFSTAT.ERROR](state, action) {
    const {
      payload: { diffStatErrorCode, diffStatErrorKey },
    } = action;

    return {
      ...state,
      diffStatErrorCode,
      diffStatErrorKey,
      isDiffStatLoading: false,
      isApprovalPending: false,
    };
  },
  [Actions.LOAD_DIFFSTAT.SUCCESS](state, action) {
    const { values } = action.payload;
    return {
      ...state,
      diffStat: values,
      isDiffStatLoading: false,
      diffStatErrorCode: null,
      diffStatErrorKey: null,
      activeDiff: '',
    };
  },
  [Actions.TOGGLE_SINGLE_FILE_MODE](state, action) {
    const isSingleFileMode = action.payload;
    return {
      ...state,
      isSingleFileMode,
    };
  },
  [Actions.UPDATE_FILTER_COMMENTS_BY_USER]: updateFilterCommentsByUser,
  [Actions.TOGGLE_SINGLE_FILE_MODE_ELIGIBILITY](state, action) {
    return {
      ...state,
      isSingleFileModeEligible: action.payload,
    };
  },
  [Actions.TOGGLE_SINGLE_FILE_MODE_SETTINGS_HEADER_VISIBILITY](state, action) {
    return {
      ...state,
      isSingleFileModeSettingsHeaderVisible: action.payload,
    };
  },
  [Actions.TOGGLE_STICKY_HEADER_ACTIVE_STATUS](state, action) {
    return {
      ...state,
      isStickyHeaderActive: action.payload,
    };
  },
  [Actions.HIGHLIGHT_COMMENT](state, action) {
    return {
      ...state,
      highlightedCommentId: action.payload.commentId,
      highlightCommentThread: action.payload.highlightThread,
    };
  },
  [Actions.HIGHLIGHT_ACTIVE_TREE_ITEM](state, action) {
    return {
      ...state,
      activeDiff: action.payload,
    };
  },
  [Actions.RESTORE_DIFFS_EXPAND_STATE](state, action) {
    return {
      ...state,
      diffsExpansionState: action.payload,
    };
  },
  [Actions.SCROLL_TO_FILE](state, action) {
    const { payload: anchorId } = action;
    const filepath = anchorId.replace(/chg-/, '');
    const nextExpansionState = {
      ...state.diffsExpansionState,
      [filepath]: true,
    };
    const activeDiff = state.isSingleFileMode ? anchorId : state.activeDiff;
    return { ...state, diffsExpansionState: nextExpansionState, activeDiff };
  },
  [Actions.OPEN_FILE_COMMENT](state, action) {
    const filepath = action.payload;

    const nextExpansionState = {
      ...state.diffsExpansionState,
      [filepath]: true,
    };

    const nextDiffCommentingMap = {
      ...state.diffCommentingMap,
      [filepath]: true,
    };

    return {
      ...state,
      diffsExpansionState: nextExpansionState,
      diffCommentingMap: nextDiffCommentingMap,
    };
  },
  [Actions.CLOSE_FILE_COMMENT](state, action) {
    const filepath = action.payload;

    const nextDiffCommentingMap = {
      ...state.diffCommentingMap,
      [filepath]: false,
    };

    return {
      ...state,
      diffCommentingMap: nextDiffCommentingMap,
    };
  },
  [Actions.TOGGLE_DIFF_EXPANSION](state, action) {
    const { filepath, isOpening } = action.payload;

    if (state.diffsExpansionState[filepath] === isOpening) {
      return state;
    }

    const nextExpansionState = {
      ...state.diffsExpansionState,
      [filepath]: isOpening,
    };
    return { ...state, diffsExpansionState: nextExpansionState };
  },
  [Actions.COLLAPSE_ALL_DIFFS](state) {
    const allFilepaths = (state.diffStat || []).map(extractFilepath);
    const allFilesCollapsed = allFilepaths.reduce(
      (prev, filepath) => ({
        ...prev,
        [filepath]: false,
      }),
      {}
    );

    return {
      ...state,
      diffsExpansionState: allFilesCollapsed,
    };
  },
  [Actions.EXPAND_ALL_DIFFS](state) {
    const allFilepaths = (state.diffStat || []).map(extractFilepath);
    const allFilesExpanded = allFilepaths.reduce(
      (prev, filepath) => ({
        ...prev,
        [filepath]: true,
      }),
      {}
    );

    return {
      ...state,
      diffsExpansionState: allFilesExpanded,
    };
  },
  [Actions.RESET_ACTIVE_TREE_ITEM](state, action) {
    if (state.activeDiff && state.activeDiff === action.payload) {
      return {
        ...state,
        activeDiff: '',
      };
    } else {
      return state;
    }
  },
  [Actions.LOAD_WATCH.BEGIN](state) {
    return {
      ...state,
      watchActionLoading: true,
    };
  },
  [Actions.LOAD_WATCH.END](state, action) {
    return {
      ...state,
      isWatching: action.payload,
      watchActionLoading: false,
    };
  },
  [Actions.ACTIVE_DIFF_PERMALINK](state, action) {
    return {
      ...state,
      activePermalink: action.payload,
    };
  },
  [Actions.PERMALINK_HASH_CHANGE](state, action) {
    const permalink = action.payload;
    if (LINE_PERMALINK_MATCHER.test(permalink)) {
      return {
        ...state,
        activePermalink: permalink,
      };
    } else {
      return state;
    }
  },
  [LoadGlobal.SUCCESS](state) {
    return state;
  },
  [Actions.CLOSE_SETTINGS_CHANGEBOARDING_DIALOG](state) {
    return {
      ...state,
      isSettingsChangeboardingDialogOpen: false,
    };
  },
  [Actions.TOGGLE_SIDE_BY_SIDE_MODE](state, action) {
    if (!action.payload.filePath) {
      return state;
    }
    const { filePath, isSideBySide } = action.payload;

    return {
      ...state,
      sideBySideDiffState: {
        ...state.sideBySideDiffState,
        [filePath]: isSideBySide,
      },
    };
  },
  [UPDATE_GLOBAL_DIFF_VIEW_MODE_SUCCESS](state) {
    return {
      ...state,
      sideBySideDiffState: initialState.sideBySideDiffState,
    };
  },
  [Actions.UPDATE_EDITOR_OPEN_STATE](state, action) {
    return {
      ...state,
      isEditorOpen: action.payload,
    };
  },
  [Actions.CHANGE_DIFF_SORT]: changeDiffSort,
  [Actions.REVERT_DIALOG.OPEN](state) {
    return {
      ...state,
      isRevertDialogOpen: true,
      revertError: null,
    };
  },
  [Actions.REVERT_DIALOG.CLOSE](state) {
    return {
      ...state,
      isRevertDialogOpen: false,
    };
  },
  [Actions.REVERT_PULL_REQUEST.REQUEST](state) {
    return {
      ...state,
      revertError: null,
    };
  },
  [Actions.REVERT_PULL_REQUEST.ERROR](state, action) {
    return {
      ...state,
      revertError: action.payload,
    };
  },
  [Actions.FETCH_DESTINATION_BRANCH.SUCCESS](state) {
    return {
      ...state,
      hasDestinationBranch: true,
    };
  },
  [Actions.FETCH_DESTINATION_BRANCH.ERROR](state) {
    return {
      ...state,
      hasDestinationBranch: false,
    };
  },
  [FetchSourceRepositoryDetails.SUCCESS](state, { payload }) {
    return {
      ...state,
      sourceRepository: {
        accessLevel: payload.access_level,
      },
    };
  },
  [Actions.FETCH_SOURCE_BRANCH_DETAILS.SUCCESS](state, action) {
    const details = {
      ...action.payload.values[0],
      isMainBranch: action.payload.values[0].ismainbranch,
    };
    return {
      ...state,
      sourceBranchDetails: details,
    };
  },
  [Actions.EXITED_CODE_REVIEW](state) {
    const { isSingleFileMode, isSingleFileModeEligible } = initialState;
    return { ...state, isSingleFileMode, isSingleFileModeEligible };
  },
  [Actions.HIDDEN_FILE_DIALOG.OPEN](state, { payload }) {
    return { ...state, hiddenFileDialogAnchor: payload };
  },
  [Actions.HIDDEN_FILE_DIALOG.CONFIRM](state) {
    return {
      ...state,
      hiddenFileDialogAnchor: null,
      // turn off comment filtering
      isCommentsFilterActive: false,
      filterCommentsByUser: null,
    };
  },
  [Actions.HIDDEN_FILE_DIALOG.CANCEL](state) {
    return { ...state, hiddenFileDialogAnchor: null };
  },
  [Actions.FIX_MISSING_COMMITS.REQUEST](state) {
    return {
      ...state,
      isUpdatingCommits: true,
    };
  },
  [Actions.FIX_MISSING_COMMITS.SUCCESS](state) {
    return {
      ...state,
      errorMessage: '',
      isMissingCommits: false,
      isUpdatingCommits: false,
    };
  },
  [Actions.FIX_MISSING_COMMITS.ERROR](state) {
    return {
      ...state,
      hasMissingCommitsError: true,
      isUpdatingCommits: false,
    };
  },
  // this action toggles the refetchBuildsDummy value, triggering a
  // builds data fetching hook to re-run
  [Actions.TOGGLE_REFETCH_BUILDS_DUMMY](state) {
    return {
      ...state,
      refetchBuildsDummy: !state.refetchBuildsDummy,
    };
  },
  [CREATE_PENDING_MERGE.ERROR](state, { payload }) {
    if (payload.code === 'BUILDS_FINISHED_GO_MERGE') {
      return {
        ...state,
        mergeChecks: state.mergeChecks.map((item: MergeCheck) => {
          if (item.name === 'Minimum Successful Builds') {
            item.pass = true;
          }
          return item;
        }),
        isMergeable: true,
      };
    }
    return state;
  },
  [Actions.REFETCH_IR_DIFF.TRUE](state) {
    return {
      ...state,
      refetchIRDiffDummy: true,
    };
  },
  [Actions.REFETCH_IR_DIFF.FALSE](state) {
    return {
      ...state,
      refetchIRDiffDummy: false,
    };
  },
});
