import { fg } from '@atlaskit/platform-feature-flags';
import { sendPackageOperationalEvent } from '../../../common/utils';
import { Logger } from '../../../common/utils/logger';
/**
 * Gets a WebStorage item synchronously. This will NOT query user preferences before setting
 * the item, so it can be used to set strictly necessary
 */
export var getStorageItem = function getStorageItem(itemKey, storageType, browserStorage) {
  var eventAttributes = {
    itemKey: itemKey,
    storageType: storageType
  };
  try {
    // Allow all items by default, but gate sending events by the flag.
    if (fg('platform.moonjelly.browser-storage-controls')) {
      sendPackageOperationalEvent({
        action: 'usedAtlBrowserStorageGetItem',
        attributes: eventAttributes
      });
    }
    return browserStorage.getItem(itemKey);
  } catch (e) {
    Logger.errorWithOperationalEvent({
      action: 'usedAtlBrowserStorageGetItemError',
      attributes: eventAttributes,
      message: "Failed to get ".concat(storageType, " item. ").concat(e.message || '')
    });
    return null;
  }
};