import React, { FC, useMemo } from 'react';

import { useSelector } from 'react-redux';

import DynamicTable from '@atlaskit/dynamic-table';
import Lozenge from '@atlaskit/lozenge';
import { Box, Flex, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { FeatureKeys } from '@atlassian/bitbucket-features';

import { useIntl } from 'src/hooks/intl';
import { useDjangoFeature } from 'src/hooks/use-django-feature';
import { getWorkspacePlan } from 'src/selectors/workspace-selectors';
import {
  BranchConfig,
  CheckTrigger,
  CheckTriggerType,
  CustomMergeCheckConfigurationState,
  CustomMergeCheckExtensionId,
  CustomMergeCheckKey,
  CustomMergeChecksResourceType,
  ForgeMergeCheckModule,
} from 'src/types/custom-merge-checks';
import { customMergeCheckKey } from 'src/utils/custom-merge-checks';

import { ForgeAppAvatar } from './forge-app-avatar';
import { MergeChecksConfigureDropdown } from './merge-checks-configure-dropdown';
import { sharedMessages } from './merge-checks-tabs.i18n';
import { messages } from './merge-checks.i18n';
import { contextMessages } from './messages.i18n';
import {
  AppColumnContainer,
  Description,
  LozengeContainer,
  MergeCheckColumnContainer,
} from './styled';

export type CustomMergeChecksConfigurationTableProps = {
  resourceType: CustomMergeChecksResourceType;
  branchConfig: BranchConfig;
  installedCheckModules: ForgeMergeCheckModule[];
  stateMap: Map<CustomMergeCheckKey, CustomMergeCheckConfigurationState>;
  checkIdBeingConfigured?: CustomMergeCheckExtensionId;
  onSubmitCheckConfiguration: (
    branchConfig: BranchConfig,
    checkModule: ForgeMergeCheckModule,
    oldState: CustomMergeCheckConfigurationState,
    newState: CustomMergeCheckConfigurationState
  ) => void;
};

/* eslint @typescript-eslint/ban-types: "warn" */
export const CustomMergeChecksConfigurationTable: FC<
  CustomMergeChecksConfigurationTableProps
> = ({
  resourceType: tableResourceType,
  branchConfig,
  installedCheckModules,
  stateMap,
  checkIdBeingConfigured,
  onSubmitCheckConfiguration,
}) => {
  const { formatMessage } = useIntl();

  const { isWorkspacePremium } = useSelector(getWorkspacePlan);

  const projectWorkspaceChecksEnabled = useDjangoFeature(
    FeatureKeys.projectWorkspaceCustomMergeChecks
  );

  const resourceTypesToRender = useMemo(() => {
    if (projectWorkspaceChecksEnabled && isWorkspacePremium) {
      switch (tableResourceType) {
        case CustomMergeChecksResourceType.Workspace:
          return [CustomMergeChecksResourceType.Workspace];
        case CustomMergeChecksResourceType.Project:
          return [
            CustomMergeChecksResourceType.Workspace,
            CustomMergeChecksResourceType.Project,
          ];
        case CustomMergeChecksResourceType.Repository:
          return [
            CustomMergeChecksResourceType.Workspace,
            CustomMergeChecksResourceType.Project,
            CustomMergeChecksResourceType.Repository,
          ];
      }
    }

    return [CustomMergeChecksResourceType.Repository];
  }, [isWorkspacePremium, projectWorkspaceChecksEnabled, tableResourceType]);

  const renderMergeCheckCell = ({
    appId,
    name: appName,
    properties: { name, description },
  }: ForgeMergeCheckModule) => {
    return (
      <Tooltip content={description} position="mouse">
        <Inline space="space.100" alignBlock="center">
          <ForgeAppAvatar appId={appId} />
          <Stack>
            <Text maxLines={1}>{name}</Text>
            <Text size="small" maxLines={1} color="color.text.subtlest">
              {appName}
            </Text>
          </Stack>
        </Inline>
      </Tooltip>
    );
  };

  const renderTriggerLozenges = (triggers: CheckTrigger[]) => {
    const hasOnMergeTrigger = triggers.includes('on-merge');
    const triggerTypes: CheckTriggerType[] = [];
    if (triggers.length > (hasOnMergeTrigger ? 1 : 0)) {
      triggerTypes.push('pre-merge');
    }
    if (hasOnMergeTrigger) {
      triggerTypes.push('on-merge');
    }

    return (
      <Flex>
        {triggerTypes.map(triggerType => (
          <LozengeContainer key={triggerType}>
            <Lozenge maxWidth={250}>
              {formatMessage(
                triggerType === 'pre-merge'
                  ? messages.preMerge
                  : messages.onMerge
              )}
            </Lozenge>
          </LozengeContainer>
        ))}
      </Flex>
    );
  };

  const renderActionCell = (
    resourceType: CustomMergeChecksResourceType,
    checkModule: ForgeMergeCheckModule
  ) => {
    const state =
      stateMap.get(
        customMergeCheckKey(resourceType, branchConfig, checkModule.id)
      ) ?? 'off';
    return (
      <Box
        xcss={xcss({
          // set min-width on interactable dropdown column to avoid reflowing the row when different items are selected
          minWidth: resourceType === tableResourceType ? '175px' : undefined,
        })}
      >
        {resourceType === tableResourceType ? (
          <MergeChecksConfigureDropdown
            checkModule={checkModule}
            branchConfig={branchConfig}
            checkState={state}
            isLoading={
              resourceType === tableResourceType &&
              checkIdBeingConfigured === checkModule.id
            }
            isDisabled={
              resourceType !== tableResourceType ||
              checkIdBeingConfigured !== undefined
            }
            onSubmitCheckConfiguration={onSubmitCheckConfiguration}
          />
        ) : (
          <Text color="color.text.subtlest" maxLines={1}>
            {formatMessage(
              state === 'required'
                ? messages.checkRequiredLabel
                : state === 'recommended'
                ? messages.checkRecommendedLabel
                : messages.checkDisabledLabel
            )}
          </Text>
        )}
      </Box>
    );
  };

  return (
    <DynamicTable
      head={{
        cells: [
          ...(projectWorkspaceChecksEnabled
            ? []
            : [{ content: formatMessage(sharedMessages.tableColumnApp) }]),
          { content: formatMessage(sharedMessages.tableColumnMergeCheck) },
          { content: formatMessage(sharedMessages.tableColumnType) },
          ...resourceTypesToRender.map(resourceType => ({
            content: projectWorkspaceChecksEnabled
              ? formatMessage(contextMessages[resourceType].title)
              : null,
          })),
        ],
      }}
      label={formatMessage(sharedMessages.configurationTableAriaLabel, {
        branchConfig: formatMessage(
          branchConfig.identifier === 'all_branches'
            ? sharedMessages.allBranchesTabLabel
            : sharedMessages.mainBranchTabLabel
        ),
      })}
      rows={installedCheckModules.map((checkModule, index) => {
        const {
          appId,
          name: appName,
          properties: { name, description, triggers },
        } = checkModule;
        return {
          key: index.toString(),
          cells: [
            ...(projectWorkspaceChecksEnabled
              ? [
                  {
                    content: renderMergeCheckCell(checkModule),
                  },
                ]
              : [
                  {
                    content: (
                      <AppColumnContainer>
                        <Inline space="space.100">
                          <ForgeAppAvatar appId={appId} />
                          <span>{appName}</span>
                        </Inline>
                      </AppColumnContainer>
                    ),
                  },
                  {
                    content: (
                      <MergeCheckColumnContainer>
                        <p>{name}</p>
                        <Description>{description}</Description>
                      </MergeCheckColumnContainer>
                    ),
                  },
                ]),
            {
              content: renderTriggerLozenges(triggers),
            },
            ...resourceTypesToRender.map(resourceType => ({
              content: renderActionCell(resourceType, checkModule),
            })),
          ],
        };
      })}
      rowsPerPage={10}
      testId="merge-checks-modules-table"
    />
  );
};
