import { pipelinesLDFeatureKeys } from './pipelines-flags';

export const FeatureKeys = {
  ...pipelinesLDFeatureKeys,
  isExampleFlag: 'example-flag',
  bbogDiscoverExperiment: 'bb.og.discover',
  useExperimentApiRecommendations: 'use-experiment-api-recommendations',
  isBuildLinkRedirectionFixEnabled: 'build-link-redirection-fix',
  isPrDependenciesEnabled: 'pr-dependencies',
  isPrReviewGroupsEnabled: 'pr-review-groups',
  sumCreateWorkspacePreChangeEnabled:
    'ADMINX-1261-workspaces-list-sum-pre-change',
  descriptivePrUpdateFlag: 'descriptive-pr-update-flag',
  extensibleMergeChecksEnabled: 'extensible-merge-checks-enabled',
  clearPrFilter: 'clear-pr-filter',
  coreUIGlobalTokenTheme: 'core-ui-global-token-theme',
  cookieConsentBannerEnabled: 'platform.moonjelly.browser-storage-controls',
  postOfficeInAppSwitcher: 'post-office-in-bitbucket-switcher',
  markFilesNewFeatures: 'mark-files-new-features',
  compassEditableEmptyState: 'bbc-compass-new-empty-state-side-card',
  disableCompassBitbucketRepositoryCard:
    'disable-compass-bitbucket-repository-card',
  enableAutoreviewFeedbackUi: 'autoreview-feedback-ui_0ya4n',
  staleBranchesFilter: 'stale-branches-filter',
  isNavThemeSelectorEnabled: 'enable-navigation-color-theme-picker',
  preventForksOutsideWorkspace: 'prevent-forking-outside-workspace',
  projectWorkspaceCustomMergeChecks:
    'project-and-workspace-custom-merge-checks',
  listOfAutoreviewCommentUuids: 'autoreview-comment-uuids',
} as const;

export type FeatureKeyType = (typeof FeatureKeys)[keyof typeof FeatureKeys];

export const pipelinesStatsigKeys = {
  inlineStopButtonEnabled: 'bitbucket_pipelines_pipelines_list_action_enabled',
  isPipelinesStepFilterEnabled: 'enable_pipelines_step_filter',
  isImprovedLogStreamingRenderingEnabled:
    'bitbucket_pipelines_improve_log_streaming_renders_',
  isPipelinesNavigationDropdownEnabled:
    'bitbucket_pipelines_navigation_dropdown_enabled',
  autoSelectBranchInModalEnabled:
    'bitbucket_pipelines_select_branch_pipeline_modal',
  buildFixAiAssistantEnabled: 'bitbucket_pipelines_build_fix_ai_assistant',
  removeAllowanceSagaEnabled: 'bitbucket_pipelines_remove_allowance_saga',
};

export const StatsigFeatureKeys = {
  ...pipelinesStatsigKeys,
  bbcNewNav: 'bbc_new_nav',
  prCreateDeleteBranchDefault: 'bbc-frontbucket-default-delete-branch',
  enableAutoreviewFeedbackUi: 'enable_autoreview_feedback_ui',
  useNew2024Plans: 'enable-bbc-price-increase-plans-core',
  disableFileEditForNonWriters: 'disable-file-edit-for-non-writers',
} as const;

export type StatsigFeatureKeyType =
  (typeof StatsigFeatureKeys)[keyof typeof StatsigFeatureKeys];
