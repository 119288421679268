import React, { FC, ReactElement } from 'react';

import { useIntl } from 'react-intl-next';

import { FocusedPageHeader } from 'src/components/accessibility';
import { SharedProps } from 'src/components/settings/create-settings-hoc';

import { messages } from './merge-checks-header.i18n';

type MergeChecksHeaderProps = SharedProps & {
  breadcrumbs: ReactElement;
};

/* eslint @typescript-eslint/ban-types: "warn" */
export const MergeChecksHeader: FC<MergeChecksHeaderProps> = props => {
  const { formatMessage } = useIntl();

  return (
    <FocusedPageHeader {...props}>
      {formatMessage(messages.header)}
    </FocusedPageHeader>
  );
};
