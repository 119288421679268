import React, { FC, useCallback } from 'react';

import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import ModalDialog, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@atlaskit/modal-dialog';

import { useIntl } from 'src/hooks/intl';
import {
  BranchConfig,
  ForgeMergeCheckModule,
  MergeCheckStateChangeType,
} from 'src/types/custom-merge-checks';

import { contextMessages, messages } from './i18n';

type ConfirmMergeCheckStateModalProps = {
  branchConfig: BranchConfig;
  checkModule: ForgeMergeCheckModule;
  isLoading: boolean;
  checkStateChangeType: MergeCheckStateChangeType;
  onConfirm: (
    branchConfig: BranchConfig,
    checkModule: ForgeMergeCheckModule,
    checkStateChangeType: MergeCheckStateChangeType
  ) => void;
  onCancel: () => void;
};

/* eslint @typescript-eslint/ban-types: "warn" */
export const ConfirmMergeCheckStateModal: FC<
  ConfirmMergeCheckStateModalProps
> = ({
  branchConfig,
  checkModule,
  isLoading,
  checkStateChangeType,
  onConfirm,
  onCancel,
}: ConfirmMergeCheckStateModalProps) => {
  const { formatMessage } = useIntl();
  const { name } = checkModule;

  const onConfirmClick = useCallback(() => {
    onConfirm(branchConfig, checkModule, checkStateChangeType);
  }, [onConfirm, branchConfig, checkModule, checkStateChangeType]);

  return (
    <ModalDialog
      width="medium"
      shouldScrollInViewport
      onClose={onCancel}
      shouldCloseOnOverlayClick={!isLoading}
      shouldCloseOnEscapePress={!isLoading}
      testId="confirm-merge-check-state-modal"
    >
      <ModalHeader>
        <ModalTitle>{name}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        {formatMessage(contextMessages[checkStateChangeType].description, {
          name: checkModule.properties.name,
        })}
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button
            appearance="subtle"
            onClick={onCancel}
            isDisabled={isLoading}
            testId="confirm-merge-check-state-cancel-btn"
          >
            {formatMessage(messages.cancelBtn)}
          </Button>
          <LoadingButton
            appearance="danger"
            onClick={onConfirmClick}
            isLoading={isLoading}
            testId="confirm-merge-check-state-confirm-btn"
          >
            {formatMessage(contextMessages[checkStateChangeType].confirmBtn)}
          </LoadingButton>
        </ButtonGroup>
      </ModalFooter>
    </ModalDialog>
  );
};
