import { fg } from '@atlaskit/platform-feature-flags';
import { sendPackageOperationalEvent } from '../../../../common/utils';
import { isProcessingAllowedByPreferences } from '../../../../common/utils/item-processing';
import { Logger } from '../../../../common/utils/logger';
import { CachedKeysDataProperty } from '../../../../types';
export var getSetCookieOverride = function getSetCookieOverride(originalSetter) {
  return function (originalCookieValue) {
    var cookieSetByPackage = originalCookieValue.includes('atl-set-cookie');
    if (cookieSetByPackage) {
      try {
        // Controls have already been run, passthrough as usual
        return originalSetter.apply(document, [originalCookieValue]);
      } catch (e) {
        Logger.errorWithOperationalEvent({
          action: 'usedDocumentCookieError',
          message: "document.cookie setter failed to set cookie set by package. ".concat(e.message || ''),
          attributes: {
            outsidePackage: false
          }
        });
      }
    }
    try {
      // Raw cookies and query params can contain sensitive PII/UGC we can't store, so we want to
      // strip that out before we log it out to our analytics client.
      var sanitizedCookieKey = originalCookieValue.slice(0, originalCookieValue.indexOf('=')) || '';
      sendPackageOperationalEvent({
        action: 'usedDocumentCookie',
        attributes: {
          cookieKey: sanitizedCookieKey,
          outsidePackage: !cookieSetByPackage
        }
      });
      try {
        // Attempt to set a known cookie that was set from outside the package
        isProcessingAllowedByPreferences({
          itemKey: sanitizedCookieKey,
          allowedCallback: function allowedCallback() {
            return originalSetter.apply(document, [originalCookieValue]);
          },
          blockedCallback: function blockedCallback(_ref) {
            var itemHasCategory = _ref.itemHasCategory;
            if (fg('platform.moonjelly.block-unknown-cookies')) {
              return;
            } else {
              if (!itemHasCategory) {
                return originalSetter.apply(document, [originalCookieValue]);
              } else {
                return;
              }
            }
          },
          cachedKeysDataProperty: CachedKeysDataProperty.Cookies
        });
      } catch (e) {
        Logger.errorWithOperationalEvent({
          action: 'usedDocumentCookieError',
          message: "Failed to set cookie. ".concat(e.message || ''),
          attributes: {
            cookieKey: sanitizedCookieKey
          }
        });
      }
    } catch (e) {
      Logger.errorWithOperationalEvent({
        action: 'usedDocumentCookieError',
        message: "Failed to send document.cookie setter event. ".concat(e.message || '')
      });
    }
  };
};