import { FeatureKeys, getFlagValue } from '@atlassian/bitbucket-features';

import { PipelineFilter } from 'src/components/pipelines/components/filters/types';
import { getPageNumber } from 'src/components/pipelines/pages/pipelines/utils';
import store from 'src/utils/store';

type FilterQuery = {
  page: number;
  ['target.branch']?: string;
  ['target.selector.type']?: string;
  ['target.selector.pattern']?: string;
  ['creator.uuid']?: PipelineFilter['uuid'];
  status?: string[];
  trigger_type?: string;
};

type Params = {
  repositoryFullSlug: string;
  branch: string;
  page: string;
};

const LOCAL_STORAGE_FILTER_KEY_PREFIX = 'pipelines-filters';

export const getLocalStorageFilters = (
  repositoryFullSlug: string
): PipelineFilter | null => {
  const localStorageFilterKey = `${LOCAL_STORAGE_FILTER_KEY_PREFIX}-${repositoryFullSlug}`;

  const localStorageFiltersString = store.get(localStorageFilterKey);
  if (!localStorageFiltersString) return null;

  return JSON.parse(localStorageFiltersString);
};

export const getLocalStorageFilterValue = (
  type: keyof PipelineFilter,
  repositoryFullSlug: string
): PipelineFilter[keyof PipelineFilter] =>
  getLocalStorageFilters(repositoryFullSlug)?.[type] || null;

export const setLocalStorageFilters = (
  repositoryFullSlug: string,
  filters: PipelineFilter
): void => {
  const localStorageFilterKey = `${LOCAL_STORAGE_FILTER_KEY_PREFIX}-${repositoryFullSlug}`;
  const localStorageFiltersString = store.get(localStorageFilterKey);
  let localStorageFilters = {};

  if (localStorageFiltersString)
    localStorageFilters = JSON.parse(localStorageFiltersString);

  store.set(
    localStorageFilterKey,
    JSON.stringify({
      ...localStorageFilters,
      ...filters,
    })
  );
};

export function buildPipelineFilterQuery(
  { repositoryFullSlug, branch, page }: Params,
  url: string
): FilterQuery {
  const filterQueries: Partial<FilterQuery> = {};
  let filters = parseFilterPathParam(url);

  const enablePersistPipelineListFilters = getFlagValue(
    FeatureKeys.enablePersistPipelineListFilters,
    false
  ) as boolean;
  if (enablePersistPipelineListFilters) {
    const haveFilters = Object.values(filters).some(value => value !== null);
    const localStorageFilters = getLocalStorageFilters(repositoryFullSlug);
    if (!haveFilters && !!localStorageFilters) {
      filters = localStorageFilters;
    }
  }

  if (branch) {
    filterQueries['target.branch'] = decodeURIComponent(branch);
  }

  if (filters.selectedPipelineType) {
    const pipelineTypeAndPattern = filters.selectedPipelineType.split(/:(.+)/);
    let type;
    switch (pipelineTypeAndPattern[0].trim().toUpperCase()) {
      case 'BRANCHES':
        type = 'BRANCH';
        break;
      case 'PULL-REQUESTS':
        type = 'PULLREQUESTS';
        break;
      default:
        type = pipelineTypeAndPattern[0].trim().toUpperCase();
    }
    filterQueries['target.selector.type'] = decodeURIComponent(type);
    if (pipelineTypeAndPattern.length > 1) {
      filterQueries['target.selector.pattern'] = decodeURIComponent(
        pipelineTypeAndPattern[1].trim()
      );
    }
  }

  if (filters.selectedStatus) {
    filterQueries.status = filters.selectedStatus.map(value =>
      value.toUpperCase()
    );
  }

  if (filters.selectedTriggerType) {
    filterQueries.trigger_type = filters.selectedTriggerType.toUpperCase();
  }

  if (filters.uuid) {
    filterQueries['creator.uuid'] = filters.uuid;
  }

  return { ...filterQueries, page: getPageNumber(page) };
}

export function buildFilterPathParam(filters: PipelineFilter): string {
  let selectedStatusQuery;
  let selectedTriggerTypeQuery;
  let selectedPipelineTypeQuery;
  let selectedCurrentUserBuildsQuery;

  if (filters && filters.selectedStatus) {
    selectedStatusQuery = `status=${filters.selectedStatus}`;
  }
  if (filters && filters.selectedTriggerType) {
    selectedTriggerTypeQuery = `triggerType=${filters.selectedTriggerType}`;
  }
  if (filters && filters.uuid) {
    selectedCurrentUserBuildsQuery = `selectedUser=${filters.uuid}`;
  }
  if (filters && filters.selectedPipelineType) {
    selectedPipelineTypeQuery = `pipelineType=${encodeURIComponent(
      filters.selectedPipelineType
    )}`;
  }

  const path = '/filters/';
  const queries = [
    selectedStatusQuery,
    selectedTriggerTypeQuery,
    selectedPipelineTypeQuery,
    selectedCurrentUserBuildsQuery,
  ]
    .filter(Boolean)
    .join('&');

  return queries ? `${path}[${queries}]` : '';
}

export function parseFilterPathParam(path: string): PipelineFilter {
  const filtersIndex = path.indexOf('/filters');
  if (filtersIndex === -1) {
    return {
      selectedStatus: null,
      selectedTriggerType: null,
      uuid: null,
      selectedPipelineType: null,
    };
  }

  const isPipelineTypeHandleSpecialChars = getFlagValue(
    FeatureKeys.isPipelineTypeHandleSpecialChars,
    false
  );

  if (isPipelineTypeHandleSpecialChars) {
    const filterPath = path.substring(filtersIndex);
    const filterQuery = filterPath.substring(
      filterPath.indexOf('[') + 1,
      filterPath.lastIndexOf(']')
    );
    const filters = filterQuery.split('&');

    const findParamValue = (paramName: string) => {
      const param = filters.find(filter => filter.startsWith(`${paramName}=`));
      return param ? decodeURIComponent(param.split('=')[1]) : null;
    };

    return {
      selectedStatus: findParamValue('status')?.split(',') ?? null,
      selectedTriggerType: findParamValue('triggerType'),
      selectedPipelineType: findParamValue('pipelineType'),
      uuid: findParamValue('selectedUser'),
    };
  }

  // Original parsing logic without decodeURIComponent
  const filterPath = decodeURIComponent(path).substring(filtersIndex);
  const filterQuery = filterPath.substring(
    filterPath.indexOf('[') + 1,
    filterPath.indexOf(']')
  );
  const filters = filterQuery.split('&');
  const selectedStatusOptional = filters.find(v => v.indexOf('status') !== -1);
  const selectedStatus = selectedStatusOptional
    ? selectedStatusOptional.split('=')[1].split(',')
    : null;

  const selectedTriggerTypeOptional = filters.find(
    v => v.indexOf('triggerType') !== -1
  );
  const selectedTriggerType = selectedTriggerTypeOptional
    ? selectedTriggerTypeOptional.split('=')[1]
    : null;

  const selectedPipelineTypeOptional = filters.find(
    v => v.indexOf('pipelineType') !== -1
  );
  const selectedPipelineType = selectedPipelineTypeOptional
    ? selectedPipelineTypeOptional.split('=')[1]
    : null;

  const selectedByUserOptional = filters.find(
    v => v.indexOf('selectedUser') !== -1
  );
  // Return the UUID here because the Avatars filter from filters component
  // expects to receive the UUID from the filters.
  const uuid = selectedByUserOptional
    ? selectedByUserOptional.split('=')[1]
    : null;
  return {
    selectedStatus,
    selectedTriggerType,
    selectedPipelineType,
    uuid,
  };
}

export const getFilterQuery = (pathname: string) =>
  buildFilterPathParam(parseFilterPathParam(pathname));

export function getFilterPathname(
  page: number,
  pathname: string,
  branch: string
): string {
  const filterPath = getFilterQuery(pathname);
  if (branch) {
    return `/results/branch/${encodeURIComponent(
      branch
    )}/page/${page}${filterPath}`;
  }
  return `/results/page/${page}${filterPath}`;
}
