import React, { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { LoadingButton } from '@atlaskit/button';
import DropdownMenu, {
  CustomTriggerProps,
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu-12';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import PremiumIcon from '@atlaskit/icon/glyph/premium';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

import { useIntl } from 'src/hooks/intl';
import { getWorkspacePlan } from 'src/selectors/workspace-selectors';
import {
  BranchConfig,
  CustomMergeCheckConfigurationState,
  ForgeMergeCheckModule,
} from 'src/types/custom-merge-checks';

import { sharedMessages } from './merge-checks-tabs.i18n';
import { messages } from './merge-checks.i18n';

type MergeChecksConfigureDropdownProps = {
  checkModule: ForgeMergeCheckModule;
  branchConfig: BranchConfig;
  checkState: CustomMergeCheckConfigurationState;
  isDisabled: boolean;
  isLoading: boolean;
  onSubmitCheckConfiguration: (
    branchConfig: BranchConfig,
    checkModule: ForgeMergeCheckModule,
    oldState: CustomMergeCheckConfigurationState,
    newState: CustomMergeCheckConfigurationState
  ) => void;
};

/* eslint @typescript-eslint/ban-types: "warn" */
export const MergeChecksConfigureDropdown: React.FC<
  MergeChecksConfigureDropdownProps
> = ({
  checkModule,
  branchConfig,
  checkState,
  isDisabled,
  isLoading,
  onSubmitCheckConfiguration,
}) => {
  const { formatMessage } = useIntl();
  const { isWorkspacePremium } = useSelector(getWorkspacePlan);

  const currentState = useMemo<CustomMergeCheckConfigurationState>(() => {
    switch (checkState) {
      case 'off':
      case 'recommended':
        return checkState;
      case 'required':
        return isWorkspacePremium ? 'required' : 'recommended';
      default:
        return 'off';
    }
  }, [checkState, isWorkspacePremium]);

  const onItemClick = useCallback(
    (newState: CustomMergeCheckConfigurationState) => {
      if (newState !== currentState) {
        onSubmitCheckConfiguration(
          branchConfig,
          checkModule,
          currentState,
          newState
        );
      }
    },
    [checkModule, branchConfig, onSubmitCheckConfiguration, currentState]
  );

  const getLabel = useCallback(
    (state: CustomMergeCheckConfigurationState) => {
      switch (state) {
        case 'required':
          return formatMessage(messages.checkRequiredLabel);
        case 'recommended':
          return formatMessage(messages.checkRecommendedLabel);
        default:
          return formatMessage(messages.checkDisabledLabel);
      }
    },
    [formatMessage]
  );

  const renderTrigger = ({
    triggerRef,
    ...props
  }: CustomTriggerProps<HTMLElement>): JSX.Element => {
    const hasOnMergeTrigger =
      checkModule.properties.triggers.includes('on-merge');
    const triggers: string[] = [];
    if (checkModule.properties.triggers.length > (hasOnMergeTrigger ? 1 : 0)) {
      triggers.push(formatMessage(messages.preMerge));
    }
    if (hasOnMergeTrigger) {
      triggers.push(formatMessage(messages.onMerge));
    }
    return (
      <LoadingButton
        {...props}
        aria-label={formatMessage(sharedMessages.checkDropdownAriaLabel, {
          appName: checkModule.name,
          checkName: checkModule.properties.name,
          triggers: triggers.join(', '),
          status: getLabel(currentState),
        })}
        appearance="subtle"
        isDisabled={isDisabled}
        isLoading={isLoading}
        ref={triggerRef}
        iconAfter={<ChevronDownIcon label="" />}
      >
        <span>{getLabel(currentState)}</span>
      </LoadingButton>
    );
  };

  const renderItem = (state: CustomMergeCheckConfigurationState) => {
    if (state !== 'required' || isWorkspacePremium) {
      return (
        <DropdownItem
          isSelected={state === currentState}
          onClick={() => onItemClick(state)}
          elemAfter={
            state === 'required' ? (
              <PremiumIcon
                label={formatMessage(messages.premiumIconLabel)}
                primaryColor={token('color.icon.information', colors.B400)}
              />
            ) : undefined
          }
        >
          {getLabel(state)}
        </DropdownItem>
      );
    }

    return (
      <Tooltip content={formatMessage(messages.requiredPremiumOnly)}>
        {tooltipProps => (
          <DropdownItem
            isDisabled
            elemAfter={
              <PremiumIcon
                label={formatMessage(messages.premiumIconLabel)}
                primaryColor={token('color.icon.information', colors.B400)}
              />
            }
            {...tooltipProps}
          >
            {getLabel(state)}
          </DropdownItem>
        )}
      </Tooltip>
    );
  };

  return (
    <DropdownMenu testId="merge-checks-module-dropdown" trigger={renderTrigger}>
      <DropdownItemGroup>
        {renderItem('off')}
        {renderItem('recommended')}
        {renderItem('required')}
      </DropdownItemGroup>
    </DropdownMenu>
  );
};
