/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import qs from 'qs';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { EditorEnum } from 'src/components/pipelines/models';
import { CLEAR_VARIABLES } from 'src/components/pipelines/redux/actions/variables';
import { LoadRepositoryPage } from 'src/sections/repository/actions';
import { getCurrentRepository } from 'src/selectors/repository-selectors';
import { getTargetUserKey } from 'src/selectors/user-selectors';
import authRequest, { jsonHeaders } from 'src/utils/fetch';

import envBaseUrl from '../../utils/env-base-url';
import {
  capturePipelinesExceptionWithTags,
  createErrorMessage,
} from '../../utils/sentry';
import {
  REQUEST_BUILD_CONFIGURATION,
  REQUEST_COMMIT_CONFIGURATION,
  REQUEST_DISABLE_PIPELINES,
  REQUEST_ENABLE_PIPELINES,
  REQUEST_OFFICIAL_TEMPLATES,
  REQUEST_REPOSITORY_BRANCH_RESTRICTIONS,
  REQUEST_REPOSITORY_FILES,
  REQUEST_REPOSITORY_USER_IS_MEMBER,
  setValidator,
  SET_REPOSITORY,
} from '../actions/pipelines';
import {
  getBuildConfiguration,
  getRepository,
  getValidator,
} from '../selectors/pipelines';

export const getPipelinesConfigUrl = (repositoryFullSlug: string) => {
  return `${envBaseUrl(
    '/!api/2.0'
  )}/repositories/${repositoryFullSlug}/pipelines_config`;
};

export const getBranchRestrictionsUrl = (repositoryFullSlug: string) => {
  return `/!api/internal/repositories/${repositoryFullSlug}/branch-restrictions/group-by-branch/`;
};

export const getBranchesUrl = (
  repositoryFullSlug: string,
  queryParams: any = {}
) => {
  return `/!api/2.0/repositories/${repositoryFullSlug}/refs/branches?${qs.stringify(
    queryParams
  )}`;
};

export const getRepositoriesUrl = (owner = '', queryParams: any = {}) => {
  return `/!api/2.0/repositories/${owner}?${qs.stringify(queryParams)}`;
};
export const getCommitConfigurationUrl = (repositoryFullSlug: string) => {
  return `/!api/2.0/repositories/${repositoryFullSlug}/src/`;
};

export const getOfficialTemplatesUrl = () => {
  return `/!api/2.0/repositories/bitbucketpipelines/official-templates/src/master/templates.prod.json`;
};

export const getBuildConfigurationUrl = (
  repositoryFullSlug: string,
  ref: string
) => {
  return `/!api/2.0/repositories/${repositoryFullSlug}/src/${ref}/bitbucket-pipelines.yml`;
};

export const getFilesUrl = (repositoryFullSlug: string, revision: string) => {
  return `/!api/internal/repositories/${repositoryFullSlug}/tree/${revision}/?no_size=1`;
};

export function* setRepositorySaga(): any {
  const repository = yield select(getCurrentRepository);
  yield put({ type: SET_REPOSITORY, payload: repository });
  yield put({ type: CLEAR_VARIABLES });
}

export function* requestBranchRestrictionsSaga(action: any): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getBranchRestrictionsUrl(full_name);

    const res: Response = yield call(fetch, authRequest(url));
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const payload = yield call([res, 'json']);
    yield put({
      type: REQUEST_REPOSITORY_BRANCH_RESTRICTIONS.SUCCESS,
      meta: action?.meta,
      payload,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_REPOSITORY_BRANCH_RESTRICTIONS.ERROR,
    });
    yield put({
      type: REQUEST_REPOSITORY_BRANCH_RESTRICTIONS.ERROR,
      meta: action?.meta,
      payload: e,
    });
  }
}

export function* requestRepositoryUserMembershipSaga(action: {
  meta: { role: string };
}): any {
  const targetUserKey = yield select(getTargetUserKey);
  const { path, hasFetchedUserIsAdmin } = yield select(getRepository);
  const role = action.meta?.role || 'admin';
  if (role === 'admin' && hasFetchedUserIsAdmin) {
    return;
  }
  try {
    const url = getRepositoriesUrl(targetUserKey, {
      role: action.meta?.role || 'admin',
      q: `full_name="${path}"`,
      pagelen: 1,
      page: 1,
    });
    const res: Response = yield call(fetch, authRequest(url));
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const payload = yield call([res, 'json']);
    yield put({
      type: REQUEST_REPOSITORY_USER_IS_MEMBER.SUCCESS,
      meta: action?.meta,
      payload,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_REPOSITORY_USER_IS_MEMBER.ERROR,
    });
    yield put({
      type: REQUEST_REPOSITORY_USER_IS_MEMBER.ERROR,
      meta: action?.meta,
      payload: e,
    });
  }
}

export function* requesOfficialTemplatesSaga(action: any): any {
  try {
    const url = getOfficialTemplatesUrl();
    const res: Response = yield call(fetch, authRequest(url));
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }

    const payload = yield call([res, 'json']);
    const templates = payload.sort((a: any, b: any) => a?.weight - b?.weight);
    yield put({
      type: REQUEST_OFFICIAL_TEMPLATES.SUCCESS,
      meta: action?.meta,
      payload: templates,
    });
    const validator = yield select(getValidator);
    const buildConfiguration = yield select(getBuildConfiguration);
    if (
      validator.currentEditor === EditorEnum.DeploymentsOnboarding &&
      (buildConfiguration.yml || templates[0]?.yml)
    ) {
      yield put(
        setValidator({ code: buildConfiguration.yml || templates[0]?.yml })
      );
    }
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_OFFICIAL_TEMPLATES.ERROR,
    });
    yield put({
      type: REQUEST_OFFICIAL_TEMPLATES.ERROR,
      meta: action?.meta,
      payload: e,
    });
  }
}

export function* requesBuildConfigurationSaga(action: {
  meta: { ref: string };
}): any {
  const { full_name, mainbranch } = yield select(getCurrentRepository);
  try {
    const url = getBuildConfigurationUrl(
      full_name,
      action.meta?.ref || mainbranch?.name
    );
    const res: Response = yield call(
      fetch,
      authRequest(url, { responseType: 'text' } as any)
    );

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const payload = yield call([res, 'text']);
    yield put({
      type: REQUEST_BUILD_CONFIGURATION.SUCCESS,
      meta: action?.meta,
      payload,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_BUILD_CONFIGURATION.ERROR,
    });
    yield put({
      type: REQUEST_BUILD_CONFIGURATION.ERROR,
      meta: action?.meta,
      payload: e,
    });
  }
}

const makeEnableDisablePipelinesSaga = (
  action: typeof REQUEST_ENABLE_PIPELINES | typeof REQUEST_DISABLE_PIPELINES,
  enabled: boolean
) =>
  function* requestEnablePipelinesSaga(): any {
    const { full_name } = yield select(getCurrentRepository);
    try {
      const url = getPipelinesConfigUrl(full_name);
      const res = yield call(
        fetch,
        authRequest(url, {
          method: 'PUT',
          headers: jsonHeaders,
          body: JSON.stringify({ enabled }),
        })
      );

      if (!res.ok) {
        throw new Error(yield createErrorMessage(res));
      }
      const payload = yield call([res, 'json']);
      yield put({
        type: action.SUCCESS,
        payload,
      });
    } catch (e) {
      capturePipelinesExceptionWithTags(e, {
        segment: enabled ? 'pipelines_enable' : 'pipelines_disable',
      });
      yield put({
        type: action.ERROR,
        payload: e,
      });
    }
  };

export const requestEnablePipelinesSaga = makeEnableDisablePipelinesSaga(
  REQUEST_ENABLE_PIPELINES,
  true
);
export const requestDisablePipelinesSaga = makeEnableDisablePipelinesSaga(
  REQUEST_DISABLE_PIPELINES,
  false
);

export function* requestCommitConfigurationSaga(action: {
  meta: { configuration: string; commitMessage: string };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getCommitConfigurationUrl(full_name);
    const res: Response = yield call(
      fetch,
      authRequest(url, {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: qs.stringify({
          ...(action.meta.configuration
            ? { 'bitbucket-pipelines.yml': action.meta.configuration }
            : {}),
          message: action.meta.commitMessage,
        }),
      })
    );
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    yield put({
      type: REQUEST_COMMIT_CONFIGURATION.SUCCESS,
      meta: action?.meta,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_COMMIT_CONFIGURATION.ERROR,
    });
    yield put({
      type: REQUEST_COMMIT_CONFIGURATION.ERROR,
      meta: action?.meta,
      payload: e,
    });
  }
}

export function* requestRepositoryFilesSaga(action: {
  meta: { revision: string };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getFilesUrl(full_name, action.meta.revision);
    const res: Response = yield call(fetch, authRequest(url));
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const payload = yield call([res, 'json']);
    yield put({
      type: REQUEST_REPOSITORY_FILES.SUCCESS,
      meta: action?.meta,
      payload,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_REPOSITORY_FILES.ERROR,
    });
    yield put({
      type: REQUEST_REPOSITORY_FILES.ERROR,
      meta: action?.meta,
      payload: e,
    });
  }
}

export default function* () {
  yield all([
    takeLatest(LoadRepositoryPage.SUCCESS, setRepositorySaga),
    takeLatest(
      REQUEST_REPOSITORY_BRANCH_RESTRICTIONS.REQUEST,
      requestBranchRestrictionsSaga
    ),
    takeEvery(
      REQUEST_REPOSITORY_USER_IS_MEMBER.REQUEST,
      requestRepositoryUserMembershipSaga
    ),
    takeLatest(REQUEST_REPOSITORY_FILES.REQUEST, requestRepositoryFilesSaga),
    takeLatest(
      REQUEST_BUILD_CONFIGURATION.REQUEST,
      requesBuildConfigurationSaga
    ),
    takeLatest(REQUEST_ENABLE_PIPELINES.REQUEST, requestEnablePipelinesSaga),
    takeLatest(REQUEST_DISABLE_PIPELINES.REQUEST, requestDisablePipelinesSaga),
    takeLatest(
      REQUEST_COMMIT_CONFIGURATION.REQUEST,
      requestCommitConfigurationSaga
    ),
    takeLatest(REQUEST_OFFICIAL_TEMPLATES.REQUEST, requesOfficialTemplatesSaga),
  ]);
}
