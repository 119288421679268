import { fg } from '@atlaskit/platform-feature-flags';
import { sendPackageOperationalEvent } from '../../../../common/utils';
import { isProcessingAllowedByPreferences } from '../../../../common/utils/item-processing';
import { Logger } from '../../../../common/utils/logger';
import { StorageType } from '../../../../services/web-storage-service';
import { CachedKeysDataProperty } from '../../../../types';

// GET SET COOKIE ANALOGUE
export var setItemOverride = function setItemOverride(bscStorage, originalSetItem, storageType) {
  return function (originalItemKey, originalItemValue) {
    var storageContext = bscStorage.storage;
    var itemSetByPackage = false;
    bscStorage.checkIfSetByPackage({
      itemKey: originalItemKey,
      isSetCallback: function isSetCallback() {
        itemSetByPackage = true;
      },
      isNotSetCallback: function isNotSetCallback() {
        // Note: This is async and could fail, but if the call errors out in IDB,
        // it will forcibly resolve to false here, so we're not catching the error again here ✅
        itemSetByPackage = false;
      }
    });
    if (itemSetByPackage) {
      try {
        // Controls have already been run, passthrough as usual
        return originalSetItem.apply(storageContext, [originalItemKey, originalItemValue]);
      } catch (e) {
        Logger.errorWithOperationalEvent({
          action: 'usedAtlBrowserStorageSetItemError',
          message: "".concat(storageType, " failed to setItem. ").concat(e.message || ''),
          attributes: {
            outsidePackage: false,
            storageType: storageType
          }
        });
      }
    }
    try {
      sendPackageOperationalEvent({
        action: 'usedAtlBrowserStorageSetItem',
        attributes: {
          itemKey: originalItemKey,
          outsidePackage: !itemSetByPackage,
          storageType: storageType
        }
      });
      try {
        // Attempt to set a known item key that was set from outside the package
        isProcessingAllowedByPreferences({
          itemKey: originalItemKey,
          allowedCallback: function allowedCallback() {
            originalSetItem.apply(storageContext, [originalItemKey, originalItemValue]);
          },
          blockedCallback: function blockedCallback(_ref) {
            var itemHasCategory = _ref.itemHasCategory;
            if (fg('platform.moonjelly.block-unknown-cookies')) {
              return;
            } else {
              if (!itemHasCategory) {
                originalSetItem.apply(storageContext, [originalItemKey, originalItemValue]);
              }
            }
          },
          cachedKeysDataProperty: storageType === StorageType.LocalStorage ? CachedKeysDataProperty.LocalStorage : CachedKeysDataProperty.SessionStorage
        });
      } catch (e) {
        Logger.errorWithOperationalEvent({
          action: 'usedAtlBrowserStorageSetItemError',
          message: "Failed to set item. ".concat(e.message || ''),
          attributes: {
            itemKey: originalItemKey,
            storageType: storageType
          }
        });
      }
    } catch (e) {
      Logger.errorWithOperationalEvent({
        action: 'usedAtlBrowserStorageSetItemError',
        message: "Failed to send ".concat(storageType, " setter event. ").concat(e.message || '')
      });
    }
  };
};