import qs from 'qs';
import { useIntl } from 'react-intl-next';
import { useResource } from 'react-resource-router';

import { FeatureKeys, useFeature } from '@atlassian/bitbucket-features';

import { loadPipelineRunsResource } from 'src/components/pipelines/resources/pipeline-runs';
import { useFlag } from 'src/hooks/flag';

import { PIPELINE_RUNS_PAGE_SIZE } from '../constants';
import { Pipeline } from '../models';
import envBaseUrl from '../utils/env-base-url';

import { messages } from './pipeline-runs.i18n';

export const getPipelineRunsUrl = (
  repositoryFullSlug: string,
  pipelineRef: string,
  queryParams = {}
): string => {
  const allQueryParams = {
    page: 1,
    pagelen: PIPELINE_RUNS_PAGE_SIZE,
    fields: '+target.commit.message,+target.commit.summary.html,+target.*,+*',
    ...queryParams,
  };
  return `${envBaseUrl(
    '/!api/internal',
    'pipelines',
    true
  )}/repositories/${repositoryFullSlug}/pipelines/${pipelineRef}/runs?${qs.stringify(
    allQueryParams,
    { allowDots: true }
  )}`;
};

export type UsePipelineRunsProps = {
  repositoryFullSlug: string | undefined;
  pipeline: Pipeline | undefined;
};

export type PipelineRunsState = {
  isLoading: boolean;
  pipelineRuns: Pipeline[];
  resetPipelineRuns: () => void;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getRunsFromResponse = (response: any): Pipeline[] =>
  response.values.map(
    (fetchedPipelineRun: any) => new Pipeline(fetchedPipelineRun)
  );

export const sortRuns = (runs: Pipeline[]): Pipeline[] =>
  runs.sort((a, b) => {
    if (!a.completed_on) {
      return -1;
    }
    if (!b.completed_on) {
      return 1;
    }
    return b.completed_on.localeCompare(a.completed_on);
  });

export function usePipelineRuns(): PipelineRunsState {
  const {
    data: resourceData,
    loading: resourceLoading,
    refresh: refreshResource,
    error: resourceError,
  } = useResource(loadPipelineRunsResource);
  const { formatMessage } = useIntl();
  const { showErrorFlag } = useFlag({ enableForPdvs: true });
  const isPipelineRunsHistoryEnabled = useFeature(
    FeatureKeys.isPipelineRunsHistoryEnabled,
    false
  ) as boolean;

  if (isPipelineRunsHistoryEnabled) {
    if (resourceError) {
      showErrorFlag(formatMessage(messages.failedFetchErrorFlagMsg));
    }

    return {
      isLoading: resourceLoading,
      pipelineRuns: resourceData || [],
      resetPipelineRuns: refreshResource,
    };
  }

  return {
    isLoading: false,
    pipelineRuns: [],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    resetPipelineRuns: () => {},
  };
}
