import React, { FunctionComponent } from 'react';

import { LinkIconButton } from '@atlaskit/button/new';
import { IconProps } from '@atlaskit/icon';
import ArrowLeftIcon from '@atlaskit/icon/glyph/arrow-left';
import Tooltip from '@atlaskit/tooltip';

import * as styles from './focused-navigation-next.style';

type Props = {
  backButtonUrl: string;
  backButtonTooltip: string;
  customBackIcon?: React.ComponentType<IconProps>;
  onBackButtonClick?: () => void;
};

const FocusedNavigation: FunctionComponent<Props> = ({
  backButtonUrl,
  customBackIcon: BackIcon,
  backButtonTooltip,
  onBackButtonClick,
}) => {
  const handleBackButtonClick = (e: React.MouseEvent) => {
    if (onBackButtonClick) {
      e.preventDefault();
      onBackButtonClick();
    }
  };

  return (
    <styles.FocusedNavigationWrapper>
      <Tooltip content={backButtonTooltip} position="right">
        <LinkIconButton
          href={backButtonUrl}
          onClick={handleBackButtonClick}
          // TODO reach out to owning team about docs for setting size
          UNSAFE_size="large"
          icon={BackIcon || ArrowLeftIcon}
          label={backButtonTooltip}
          appearance="subtle"
        ></LinkIconButton>
      </Tooltip>
    </styles.FocusedNavigationWrapper>
  );
};

export default FocusedNavigation;
