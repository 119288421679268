import { captureExceptionWithTags } from 'src/utils/sentry';

import { PIPELINES_SENTRY_PRIMARY_TAG } from '../constants';

const SKIP_FLAG = '__skip__';

export const RESPONSE_UNDEFINED = 'Response undefined';
export const RESPONSE_CONTENT_UNDEFINED = 'Response content undefined';

const extracMessageFromJSON = (
  json: any,
  defaultMessage = RESPONSE_CONTENT_UNDEFINED
): string => {
  // our server seems returning a inconsistent data structure for errors ATM
  // It uses {error: {meesage: string}} and {message: string} interchangably
  if (json.error?.message || json.message) {
    return json.error?.message || json.message;
  }
  if (JSON.stringify(json) !== '{}') {
    return JSON.stringify(json);
  }
  return defaultMessage;
};

/**
 * A function that will return a more specific error from the Response object.
 * Make sure it's being used before res.json() or res.text() is called,
 * otherwise it's throwing `Failed to execute 'clone' on 'Response'` error.
 * @param res - the error response
 * @returns - error message
 */
export const createErrorMessage = async (res: Response): Promise<string> => {
  if (!res) return RESPONSE_UNDEFINED;
  try {
    // try catch is needed to avoid calling .json() on an empty response, i.e. new Resposne() or new Response('')
    // which will throw an Unexpected end of JSON input error to Sentry
    let errorMessage = `${res.status}: ${res.statusText}`;
    const resClone = res.clone(); // using clone here to avoid calling `.json` or `.text` directly to cause a `Already Read` error
    const contentType = resClone.headers.get('Content-Type') || '';
    if (contentType.includes('application/json')) {
      const json = await resClone.json();
      errorMessage = extracMessageFromJSON(json);
    } else {
      const text = await resClone.text();
      if (!text || !text.length) {
        return RESPONSE_CONTENT_UNDEFINED;
      }
      errorMessage = text || errorMessage;
    }
    return errorMessage;
  } catch (error) {
    return error.toString();
  }
};

const defaultSkipMessages = [
  // resource not found
  'Resource not found',
  'Not found',
  // enable two-step verifications
  'To access this team, enable two-step verification.',
  'To access this user, enable two-step verification.',
  'To access this repository, enable two-step verification.',
  // whitelist ip address
  'To access this repository, an admin must whitelist your IP.',
  'To access this user, an admin must whitelist your IP.',
  'To access this team, an admin must whitelist your IP.',
  // access limits
  'You may not have access to this repository or it no longer exists in this workspace',
  // gateway errors
  '<html> <head><title>504 Gateway Time-out</title>',
  '<html> <head><title>502 Bad Gateway</title>',
];

export const isIgnoredErrorForSentry = (
  e: Maybe<Error>,
  messagesToSkip: string[] = []
): boolean => {
  const skipMessages = [...defaultSkipMessages, ...messagesToSkip].map(
    message => message.toLowerCase()
  );
  if (!e?.message) return false;
  return (
    skipMessages.includes(e.message.toLowerCase()) ||
    skipMessages.some(message => e.message.toLowerCase().startsWith(message))
  );
};

/**
 * This function wraps `captureExceptionWithTags` with a preset tag - app:pipelines.
 * @param tags - Object, optional
 * @param extras - Object, optional
 * @returns - void
 */
export const capturePipelinesExceptionWithTags = (
  e: Maybe<Error>,
  tags?: { [key: string]: string },
  extras?: { [key: string]: string },
  messagesToSkip: string[] = []
): void => {
  if (isIgnoredErrorForSentry(e, messagesToSkip)) return;

  if (e?.message?.toLowerCase() === SKIP_FLAG) return;

  const mergedTags = {
    ...tags,
    app: PIPELINES_SENTRY_PRIMARY_TAG,
  };
  captureExceptionWithTags(e!, mergedTags, extras);
};
