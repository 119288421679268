import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { getConfig as getConfigUFO } from '../../../config';
var defaultAllowedParams = ['operationName', 'operation', 'q'];
var getAllowedParams = function getAllowedParams() {
  var config = getConfigUFO();
  return (config === null || config === void 0 ? void 0 : config.allowedResourcesParams) || defaultAllowedParams;
};
var handleQueryParams = function handleQueryParams(urlString) {
  try {
    if (typeof urlString !== 'string') {
      return urlString;
    }
    var url = new URL(urlString);
    var params = new URLSearchParams(url.search);
    var allowedParams = getAllowedParams();
    var _iterator = _createForOfIteratorHelper(params),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _step$value = _slicedToArray(_step.value, 1),
          key = _step$value[0];
        if (!allowedParams.includes(key)) {
          url.searchParams.delete(key);
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    return url.toString();
  } catch (e) {
    return urlString;
  }
};
var config = {
  mapResources: function mapResources(url) {
    return url;
  },
  sanitiseEndpoints: function sanitiseEndpoints(url) {
    return handleQueryParams(url);
  }
};
export function configure(resourceTimingConfig) {
  var newConfig = {
    mapResources: resourceTimingConfig.mapResources,
    sanitiseEndpoints: function sanitiseEndpoints(url) {
      var sanitised = resourceTimingConfig.sanitiseEndpoints(url);
      if (sanitised) {
        return handleQueryParams(sanitised);
      }
      return sanitised;
    }
  };
  config = newConfig;
}
export var getConfig = function getConfig() {
  return config;
};